import * as React from 'react';
import * as ReactDOM from 'react-dom';
import '@rmwc/theme/styles';
import './styles/index.css';
//import registerServiceWorker from './registerServiceWorker';

import { RMWCProvider } from '@rmwc/provider';
//import { runWithAdal } from 'react-adal';
//import { authContext } from './adalConfig';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

//const DO_NOT_LOGIN = false;

//var urlParams = new URLSearchParams(document.location.href);

//const apiTokenExists = urlParams.has('token');
//const apiTokenExists = document.location.href.indexOf('token=') != -1 && document.location.href.indexOf('id_token=') === -1 && document.location.href.indexOf('access_token=') === -1;


ReactDOM.render(
  <RMWCProvider
    // Globally enable/ disable ripples
    ripple={true}
    // Global options for icons
    // Takes the same options as the icon component
    icon={{
      basename: 'material-icons'
    }}
    // Global options for typography
    // allows mapping of a defaultTag or specific classes
    // See the Typography docs for more info
    typography={{
      defaultTag: 'div',
      headline1: 'h1'
    }}
    // Global options for tooltips
    // Takes most of the options for tooltips
    // See the Tooltip docs for more info
    tooltip={{
      align: 'right'
    }}
  >
    {/*  browser router must be defined here to enable to use history in App component */}
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </RMWCProvider>,
  document.getElementById('root') as HTMLElement
);


/* if (apiTokenExists) {

  console.debug("API token exists in url 'checked existence of 'token=', using version without runWithAdal")
  ReactDOM.render(
    <App />,
    document.getElementById('root') as HTMLElement
  );
  registerServiceWorker();

} else {

  console.debug("API token doesn't exists in url 'checked existence of 'token=', using version with runWithAdal => resulting in the empty screen in iframes!!!")
  runWithAdal(authContext, () => {
    ReactDOM.render(
      <App />,
      document.getElementById('root') as HTMLElement
    );
    registerServiceWorker();

  }, DO_NOT_LOGIN);

} */



/* runWithAdal(authContext, () => {
  ReactDOM.render(
    <App />,
    document.getElementById('root') as HTMLElement
  );
  registerServiceWorker();

}, DO_NOT_LOGIN); */

