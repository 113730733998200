import React from 'react';
import styles from './Error.module.scss';
import { Icon } from '@rmwc/icon';
import '@rmwc/icon/styles';
export interface IErrorProps {
  title?: string;
  description?: string;

}
const Error: React.FC<IErrorProps> = ({ title, description, children }) => {


  return <div className={`mt-4 mb-4`}>
    <div className={`${styles.vCenter} ${styles.errorRed}`}><Icon icon={{ icon: 'warning', size: 'medium' }} /><h3 className="ml-3">{title || 'Error'}</h3></div>
    <div className={`${styles.font14} ${styles.errorRed} mb-4`}>{description}</div>
    <div >{children}</div>
  </div>
};

export default Error;
