
import styles from './ProgressTracker.module.scss';
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { Icon } from '@rmwc/icon';
import '@rmwc/button/styles';
import '@rmwc/select/styles';
import '@rmwc/icon/styles';
export interface IProgressTrackerProps {
  stepsCount?: number;
  selectedStepNr?: number;
  width?: string;
}


const ProgressTracker = ({ stepsCount, selectedStepNr, width }: IProgressTrackerProps) => {
  //const steps = propSteps || defaultSteps;
  stepsCount = stepsCount || 3;
  selectedStepNr = selectedStepNr || 1;
  width = width || "100%";


  const steps = [];
  for (let index = 0; index < stepsCount; index++) {

    steps.push(<Step
      key={`step_${index + 1}`}
      position={100 * (index / stepsCount)}
      transition="scale"
      index={index}
      children={({ accomplished }) => {

        const isCurrent = selectedStepNr === index + 1;

        return <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            width: 24,
            height: 24,
            color: accomplished || isCurrent ? "white" : "gray",
            border: accomplished || isCurrent ? "none" : "1px solid gray",
            backgroundColor: accomplished || isCurrent ? "#00A4EC" : "white"
          }}
        >
          {accomplished ? <Icon icon={{ icon: 'check', size: 'xsmall' }} /> : index + 1}
        </div>

      }}
    />);
  }

  return (
    <div className={styles.ProgressTracker}>
      <ProgressBar
        height={1}
        width={width}
        percent={100 * ((selectedStepNr - 1) / (stepsCount - 1))}
        filledBackground="grey"
      >
        {steps}
      </ProgressBar>

    </div>);
};

export default ProgressTracker;
