import React from 'react';
//import styles from './ExternalProgress.module.scss';
import DialogContainer from '../DialogContainer/DialogContainer';
import Progress from '../Progress/Progress';

export interface IExternalProgressProps {
  text: string
}
const ExternalProgress = ({ text }: IExternalProgressProps) => {


  return <DialogContainer showTitle={false} showProgressBar={false} showCross={false}>
    <Progress text={text} />
  </DialogContainer>
};

export default ExternalProgress;
