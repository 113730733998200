import { Grid } from '@mui/material';
import { GridRow, GridCell } from '@rmwc/grid';
import DialogContainer from 'components/DialogContainer/DialogContainer';
import { StyledButton } from 'components/Forms/Button';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './SignIn.module.scss';
import { useAppSettings } from 'services/models/AppSettings';
import { AppContext } from 'AppContext';

export interface ISignInProps {
  afterRegistrationRedirectUrl: string;
  afterRegistrationRedirectLinkText: string;
}

const SignIn = ({ afterRegistrationRedirectUrl, afterRegistrationRedirectLinkText }: ISignInProps) => {

  const history = useHistory();
  const context = useContext(AppContext);
  const { appSettings, loaded: appSettingsLoaded } = useAppSettings(context);

  const [topRowContent, setTopRowContent] = useState<ReactNode>('');

  useEffect(() => {
    //=====================   logos from settings  ==============================
    if (appSettingsLoaded) {
      setTopRowContent(<>
        <GridCell desktop={4} tablet={3} phone={1}  ><img className={styles.logo} src={appSettings!.DhiLogoUrl} alt="DHI logo" /></GridCell>
        <GridCell desktop={4} tablet={2} phone={2}  ><img className={styles.logo} src={appSettings!.AcademyLogoUrl} alt="Academy logo" /></GridCell>
        <GridCell desktop={4} tablet={3} phone={1}  ><img className={styles.logo} src={appSettings!.MikeCloudLogoUrl} alt="Mike cloud logo" /></GridCell>
      </>);
    }
    //=====================   end logos from setting  ==============================
  }, [appSettingsLoaded, appSettings, setTopRowContent])



  return <DialogContainer showTitle={false} showProgressBar={false} topRowContent={topRowContent}>
    <div className={styles.SignIn}>
      <Grid>
        <GridRow>
          <GridCell desktop={12} tablet={8} phone={4} className={`${styles.hCenter} mb-6 ${styles.title}`} ><b>OneLogin</b>&nbsp;powered by MIKE Cloud</GridCell>
        </GridRow>
        <GridRow className="mt-2">
          <GridCell desktop={6} tablet={4} phone={2} className={`${styles.hRight} mr-1`} >
            <StyledButton
              type="button"
              className={styles.formButton}
              onClick={(e) => {
                (window.top || window).location.replace(decodeURIComponent(afterRegistrationRedirectUrl));
              }}
              variant="primary">SIGN IN</StyledButton>
          </GridCell>
          <GridCell desktop={6} tablet={4} phone={2} className={`${styles.hLeft} ml-1`} >
            <StyledButton
              type="button"
              className={styles.formButton}
              onClick={() => { history.push('/registration', { redir: afterRegistrationRedirectUrl, redirtxt: afterRegistrationRedirectLinkText }); }}
              variant="secondary">REGISTER</StyledButton>
          </GridCell>
        </GridRow>
      </Grid>
    </div >
  </DialogContainer>

};

export default SignIn;
