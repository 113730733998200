
import MuiTextField from '@material-ui/core/TextField';
import { styled } from "@mui/material/styles";
import { TextFieldProps } from '@material-ui/core';
import { FormikProps } from 'formik';

export interface ITextFieldProps {
  formikProps: FormikProps<any>;
}

const StyledTextField = styled(MuiTextField)(() => ({
  width: "100%",
  maxWidth: "600px",
  marginBottom: "18px !important",
  // input label when focused
  "& label.Mui-focused": {
    color: '#00A3EA'
  },
  // focused color for input with variant='standard'
  "& .MuiInput-underline:after": {
    borderBottomColor: '#00A3EA'
  },
  // focused color for input with variant='filled'
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: '#00A3EA'
  },
  // focused color for input with variant='outlined'
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: '#00A3EA'
    }
  }
}));

export const TextField = (props: (TextFieldProps & ITextFieldProps)) => {

  const { touched, errors, handleChange, handleBlur } = props.formikProps;
  const { name, onChange, onBlur, helperText: helperTextProp } = props;

  const error = touched[name!] && Boolean(errors[name!]);
  const helperText = error ? errors[name!] : (helperTextProp || undefined);
  //if (error) console.log("TxtField " + name + " errors:" + helperText);

  //remove formikProps from props - causes problems
  const {
    formikProps,
    type,
    autoComplete,
    ...otherProps 
  } = props;
  otherProps.helperText = helperText;

  return <StyledTextField
    name={name}
    variant="filled"
    onChange={onChange || handleChange}
    onBlur={onBlur || handleBlur}
    error={error}
    helperText={helperText}
    type="search"
    autoComplete="off"
    {...otherProps} />
};

