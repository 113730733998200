import DialogContainer from 'components/DialogContainer/DialogContainer';
import React from 'react';
import styles from './PageCanBeClosedPage.module.scss';


const PageCanBeClosedPage = () => {


  return <DialogContainer title="This page can be closed now" showTitle={true} showProgressBar={false} showCross={false} stepsCount={5} selectedStepNr={5}>

    <div className={`${styles.font14} ${styles.brandBlue} mt-4 mb-4 ml-4 mr-4`}>

      <div>
        We would close the page automatically, however the browser's security restrictions doesn't allow us to do it, unfortunately.
      </div >

    </div>

  </DialogContainer >
};

export default PageCanBeClosedPage;
