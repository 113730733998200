export const getUrlParameter = (urlParams: URLSearchParams, name: string, required: boolean = false, paramDescription: string = "", defaultValue: string = "") => {

    if (!urlParams.has(name)) {
        if (required) {
            if (defaultValue === "") {
                let msg = `Missing required url parameter '${name}' ${paramDescription === "" ? "" : (`(${paramDescription})`)} in the url, no default value specified`;
                console.error(msg)
                throw new Error(msg);
            } else {
                let msg = `Missing required url parameter '${name}' ${paramDescription === "" ? "" : (`(${paramDescription})`)} in the url, used specified default value: ${defaultValue}`;
                console.log(msg);
                return defaultValue;
            }
        }
        else {
            if (defaultValue === "") {
                console.warn(`Missing optional url parameter '${name}' ${paramDescription === "" ? "" : (`(${paramDescription})`)} in the url, no default value specified`);
                return undefined;
            } else {
                console.log(`Missing optional url parameter '${name}' ${paramDescription === "" ? "" : (`(${paramDescription})`)} in the url, used specified default value: ${defaultValue}`);
                return defaultValue;
            }
        }
    }
    return urlParams.get(name);
}