import { ApplicationInsights, SeverityLevel } from "@microsoft/applicationinsights-web";

export interface ILogger {
    logDebug: (message: string) => void;
    logInfo: (message: string) => void;
    logWarning: (message: string) => void;
    logError: (error: any) => void;
    logEvent: (eventName: string, eventProperties?: { [key: string]: any; }) => void;
}
export class Logger implements ILogger {

    private appInsights: ApplicationInsights;
    private correlationId: string;
    private logToConsole: boolean;
    /**
     *
     */
    constructor(appInsights: ApplicationInsights, correlationId: string, logToConsole: boolean) {

        this.appInsights = appInsights;
        this.correlationId = correlationId;
        this.logToConsole = logToConsole;
    }

    logDebug = (message: string) => {
        if (this.logToConsole) console.debug(message);
        this.appInsights.trackTrace({ message: message }, { 'correlationId': this.correlationId });
    }

    logInfo = (message: string) => {
        if (this.logToConsole) console.log(message);
        this.appInsights.trackTrace({ message: message }, { 'correlationId': this.correlationId });
    }

    logWarning = (message: string) => {
        if (this.logToConsole) console.warn(message);
        this.appInsights.trackTrace({ message: message, severityLevel: SeverityLevel.Warning }, { 'correlationId': this.correlationId });
    }

    logError = (error: any) => {
        if (this.logToConsole) console.error(error);
        this.appInsights.trackException({ exception: error, severityLevel: SeverityLevel.Error, properties: { 'correlationId': this.correlationId } });
    }

    logEvent = (eventName: string, eventProperties?: { [key: string]: any }) => {
        if (this.logToConsole) console.info(`Event ${eventName} ocurred.`);
        this.appInsights.trackEvent({ name: eventName, properties: eventProperties }, { 'correlationId': this.correlationId });
    }
}