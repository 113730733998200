
import '@rmwc/checkbox/styles';
import { Checkbox } from '@rmwc/checkbox';
import { Select } from 'components/Forms/Select/Select';
import { Form, Formik, FormikProps } from 'formik';
import React, { ChangeEvent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../AppContext';
import styles from './ClientRegistration.module.scss';
import * as Yup from 'yup';
import { Country } from 'services/models/Country';
import { Contact } from 'services/models/Contact';
import { StyledButton } from 'components/Forms/Button';//LinkButton, 
import { State } from 'services/models/State';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';


import '@rmwc/textfield/styles';
import { Grid, GridCell, GridRow } from '@rmwc/grid';
import { ContactInfo } from 'services/models/ContactInfo';
import { TextField } from 'components/Forms/TextField';
import Progress from 'components/Progress/Progress';
import { useHistory } from 'react-router-dom';
import { GoogleRecaptchaVerificationResponse } from 'services/models/GoogleRecaptchaVerificationResponse';
import { ServiceResponse } from 'services/api-common/ApiResponse';
import { ContactRequest } from 'services/models/ContactRequest';
import { InputValidation } from 'utils/InputValidation';


export interface IUserRegistrationProps {
    onVerificationEmailSent: (newContact: Contact) => void;
    onUserCreationRequested: (request: ContactRequest) => void;
    onUserExists: (contactInfo: ContactInfo) => void;
    onProcessingChanged: (running: boolean) => void;
    afterRegistrationRedirectUrl: string;
    afterRegistrationRedirectLinkText: string;
    email: string;
    contactCode?: string;
    cancelRedirectUrl?: string;
    onCancelAction?: string;
}

enum ProcessState {
    initialized,
    optionListsLoaded,
    requestSent,
    requestFinished,
    error
}

export interface FormValues {
    marketingMaterialsConsent: boolean;
    firstName: string;
    lastName: string;
    email: string;
    countryId: string;
    stateId: string;
}


const nameErrorText = 'Only letters, numbers, spaces, period, comma, dash and slash are allowed';
const emailErrorText = "Only letters, numbers, underscore and hyphen are allowed.";
export const createRegistrationFormSchema = (stateRequired: boolean) =>
  Yup.object().shape({
    firstName: Yup.string()
      .matches(InputValidation.patterns.alphaNumeric, nameErrorText)
      .min(2, "Your name seems to be too short")
      .max(
        50,
        "Your name seems to be too long. Use at maximum 50 characters, please."
      ),
    lastName: Yup.string()
      .matches(InputValidation.patterns.alphaNumeric, nameErrorText)
      .min(2, "Your last name seems to be too short")
      .max(
        50,
        "Your last name seems to be too long. Use at maximum 50 characters, please."
      )
      .required("Your last name is required"),
    email: Yup.string()
      .required("Email is required")
      .test("email-valid", emailErrorText, (value: string | undefined) => {
        if (typeof value === "string") {
          return InputValidation.isEmailValid(value);
        }
        return false;
      }),
    countryId: Yup.string().required("Required"),
    stateId: Yup.string().when(["countryId"], {
      is: (countryId: string) => {
        return countryId !== null && stateRequired;
      },
      then: Yup.string().required("Required"),
    }),
  });


const UserRegistration = ({ email: emailProp, onVerificationEmailSent, onUserExists, onProcessingChanged, onUserCreationRequested,
    afterRegistrationRedirectUrl, afterRegistrationRedirectLinkText, cancelRedirectUrl, onCancelAction, contactCode }: IUserRegistrationProps) => {
    const context = useContext(AppContext);
    const [processState, setProcessState] = useState<ProcessState>(ProcessState.initialized);
    const [states, setStates] = useState<State[]>();
    const [countries, setCountries] = useState<Country[]>();
    const [error, setError] = useState();
    const [stateRequired, setStateRequired] = useState<boolean>(false);
    const [contactInfo, setContactInfo] = useState<ContactInfo | null>(null);
    const [email, setEmail] = useState<string>(emailProp);
    const [emailChecking, setEmailChecking] = useState<boolean>(false);
    const [emailChecked, setEmailChecked] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const chkGdprRef = useRef<HTMLInputElement>(null);
    const history = useHistory();

    const newUserFormInitialValues: FormValues = {
        marketingMaterialsConsent: false,
        firstName: '',
        lastName: '',
        email: email,
        countryId: '',
        stateId: '',
    }

    const [captchaValidated, setCaptchaValidated] = useState(false);
    //------------------------------------------------
    //  RECAPTCHA
    //------------------------------------------------
    const { executeRecaptcha } = useGoogleReCaptcha();
    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        const token = await executeRecaptcha('UserRegistration');
        console.log(`reCaptcha token = ${JSON.stringify(token)}`);
        context.customersApiService!.VefifyReCaptchaToken(token)
            .then((data: ServiceResponse<GoogleRecaptchaVerificationResponse>) => {
                const response: GoogleRecaptchaVerificationResponse = data.response;
                setCaptchaValidated(response.score >= 0.5);
                if (response.score >= 0.5)
                    console.log("ReCaptcha validated, user seems to be human");
                else
                    console.error("ReCaptcha not validated, user seems to be robot!");
            });

    }, [executeRecaptcha, context.customersApiService]);
    // You can use useEffect to trigger the verification as soon as the component being loaded
    useEffect(() => {
        handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);

    //------------------------------------------------
    //  END RECAPTCHA
    //------------------------------------------------
    /// run just once at the component loading
    useEffect(() => {
        newUserFormInitialValues.email = email || '';

        const bsApimSvc = context.bsApimService!;
        bsApimSvc!.GetCountries().then((sResponse) => { setCountries(sResponse.response); });

        //if eny email was defined in the url parameters, validate it - just once. later the validation is done in email textbox onBlur event
        if (email) checkEmailAlreadyRegistered(email);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) //<-- run just once
    useEffect(() => {
        onProcessingChanged(processState === ProcessState.requestSent);
    }, [processState, onProcessingChanged])


    /**
     * 
     * @param email Check the email whether it's already registered in the Dynamics
     */
    const checkEmailAlreadyRegistered = async (email: string) => {
        const customersAPI = context.customersApiService!;

        try {
            setEmailChecking(true);
            const contactInfo = (await customersAPI!.GetContactInfo(email)).response;
            if (contactInfo.exists) {
                setEmail(email);
                setContactInfo(contactInfo);
                if (contactInfo.gdprConsentProvided && chkGdprRef.current)
                    chkGdprRef.current.checked = true;

            } else {
                setEmail('');
                setContactInfo(null);
            }
            setEmailChecking(false);
            setEmailChecked(true);

        } catch (error: any) {
            setProcessState(ProcessState.error);
            setError(error);
        }

    }

    const inInvitationFlow = (): boolean => {
        return emailProp !== "";
    }

    /**
     * 
     * @returns Returns true if the email has been entered, checked and the user is already registered in Dynamics
     */
    const emailRegisteredInDynamics = (): boolean => {
        return email != null && contactInfo != null;
    }
    /**
     * 
     * @returns Returns true if the email has been entered, checked and the user is already registered only in Dynamics
     */
    const emailRegisteredInDynamicsOnly = (): boolean => {
        return emailRegisteredInDynamics() && !contactInfo!.registeredInMikeCloud;
    }

    /**
    * 
    * @returns Returns true if the email has been entered, checked and the user is already registered both in Dsynamics and MikeCloud
    */
    const emailRegisteredInMike = (): boolean => {
        return emailRegisteredInDynamics() && contactInfo!.registeredInMikeCloud;
    }

    const isNewContact = (): boolean => {
        return emailChecked && !emailRegisteredInDynamics();
    }

    /**
     * Component render
     */
    return <div>
        {processState === ProcessState.initialized &&
            <Formik

                initialValues={newUserFormInitialValues}
                onSubmit={(values, actions) => {
                    const CreateContact = async (values: Contact) => {

                        const newContact: Contact = values as Contact;
                        if (newContact.stateId === '') newContact.stateId = undefined;
                        newContact.redirectUrlAfterRegistration = `${afterRegistrationRedirectUrl}|${afterRegistrationRedirectLinkText}`;
                        const customersAPI = context.customersApiService!;
                        try {
                            if (contactCode === undefined) {
                                //standard flow
                                await customersAPI.EncryptContactAndSendEmail(newContact);
                                setProcessState(ProcessState.requestFinished);
                                //raise the event to notify parent
                                onVerificationEmailSent(newContact);
                            } else {
                                // invitation flow
                                setProcessState(ProcessState.requestFinished);
                                //raise the event to notify parent
                                onUserCreationRequested({
                                    vericode: contactCode,
                                    contact: newContact
                                });
                            }


                        } catch (err: any) {
                            setProcessState(ProcessState.error);
                            setError(err.message);
                        }

                    }
                    CreateContact({ ...values, redirectUrlAfterRegistration: afterRegistrationRedirectUrl });
                    setProcessState(ProcessState.requestSent);
                    actions.setSubmitting(false);
                }}
                validationSchema={createRegistrationFormSchema(stateRequired)}
            >
                {(formikProps: FormikProps<any>) => {
                    const { errors,
                        values,
                        setFieldValue,
                        handleChange,
                        handleBlur,
                        isSubmitting,
                        //validateField,
                    } = formikProps;
                    return (
                      <Form ref={formRef}>                        
                        <Grid>
                          <GridRow></GridRow>
                          {(!emailChecked || !emailRegisteredInDynamics()) && (
                            <GridRow>
                              <GridCell
                                desktop={emailChecked ? 12 : 8}
                                tablet={6}
                                phone={
                                  !inInvitationFlow() && !isNewContact() ? 3 : 4
                                }
                                align="middle"
                                className={
                                  emailChecked ? "" : `${styles.hRight} mr-1`
                                }
                              >
                                <TextField
                                  name="email"                                  
                                  label="Email"
                                  helperText={
                                    emailChecking
                                      ? "Checking email..."
                                      : undefined
                                  }
                                  disabled={emailProp !== "" || emailChecking}
                                  onChange={(e: ChangeEvent) => {
                                    handleChange(e);
                                    setEmailChecked(false);
                                  }}
                                  value={values.email}
                                  className={styles.formControl}
                                  formikProps={formikProps}                                  
                                  required
                                />
                              </GridCell>

                              {!inInvitationFlow() && !isNewContact() && (
                                // Show the check email button, but only when the email was not received as a prop (invitation flow)
                                <GridCell
                                  desktop={4}
                                  tablet={2}
                                  phone={1}
                                  align="middle"
                                  className={`${styles.hLeft} ml-1`}
                                >
                                  <StyledButton
                                    type="button"
                                    className={styles.formButton}
                                    onClick={() => {
                                      if (
                                        values.email != null &&
                                        values.email.trim() !== "" &&
                                        !Boolean(errors.email)
                                      ) {
                                        checkEmailAlreadyRegistered(
                                          values.email
                                        );
                                      }
                                    }}
                                  >
                                    Next
                                  </StyledButton>
                                </GridCell>
                              )}
                            </GridRow>
                          )}

                          {emailRegisteredInDynamicsOnly() && (
                            <>
                              {/* When the email is already registered only in Dynamics, the Continue dialog should be open */}
                              <GridRow>
                                <GridCell
                                  desktop={12}
                                  tablet={8}
                                  phone={4}
                                  align="middle"
                                  className={`${styles.emailExists} ${styles.font14} mt-10`}
                                >
                                  We already know this email, but your
                                  registration hasn't been finished yet.
                                </GridCell>
                              </GridRow>
                              <GridRow>
                                <GridCell
                                  desktop={12}
                                  tablet={8}
                                  phone={4}
                                  align="middle"
                                  className={`${styles.hCenter} mb-10`}
                                >
                                  <StyledButton
                                    type="button"
                                    className={`${styles.formButton} ${styles.wide}`}
                                    onClick={(e) => {
                                      // validateField('marketingMaterialsConsent');
                                      // if (!errors.marketingMaterialsConsent) {
                                      setProcessState(ProcessState.requestSent);
                                      onUserExists(contactInfo!);
                                      /* }
                                                    else {
                                                        formRef.current?.reportValidity();
                                                    }
 */
                                    }}
                                  >
                                    Continue in registration
                                  </StyledButton>
                                </GridCell>
                              </GridRow>
                            </>
                          )}
                          {emailRegisteredInMike() && (
                            <>
                              <GridRow>
                                <GridCell
                                  desktop={12}
                                  tablet={8}
                                  phone={4}
                                  align="middle"
                                  className={`${styles.emailExists} ${styles.font14}`}
                                >
                                  You are already registered.
                                  {/*  <LinkButton
                                            type="button"
                                            onClick={(e) => {
                                                (window.top || window).location.replace(afterRegistrationRedirectUrl);

                                            }} >Log-in here</LinkButton>. */}
                                </GridCell>
                              </GridRow>
                              <GridRow>
                                <GridCell
                                  desktop={12}
                                  tablet={8}
                                  phone={4}
                                  className={`${styles.hCenter} mr-1`}
                                >
                                  <StyledButton
                                    type="button"
                                    className={styles.formButton}
                                    onClick={(e) => {
                                      (window.top || window).location.replace(
                                        afterRegistrationRedirectUrl
                                      );
                                    }}
                                    variant="primary"
                                  >
                                    SIGN IN
                                  </StyledButton>
                                </GridCell>
                              </GridRow>
                            </>
                          )}

                          {isNewContact() && ( //(inInvitationFlow() || isNewContact()) &&
                            // When the email was received in the prop (invitation flow)
                            // or was not already registered in the Dynamics, open the rest of form fields
                            <>
                              <GridRow>
                                <GridCell
                                  desktop={12}
                                  tablet={8}
                                  phone={4}
                                  align="middle"
                                >
                                  <TextField
                                    name="firstName"
                                    label="First name"                                    
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.firstName}
                                    className={styles.formControl}
                                    disabled={contactInfo !== null}
                                    formikProps={formikProps}
                                  />
                                </GridCell>
                              </GridRow>
                              <GridRow>
                                <GridCell
                                  desktop={12}
                                  tablet={8}
                                  phone={4}
                                  align="middle"
                                >
                                  <TextField
                                    name="lastName"
                                    label="Last name"                                    
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.lastName}
                                    className={styles.formControl}
                                    disabled={contactInfo !== null}
                                    formikProps={formikProps}
                                    required
                                  />
                                </GridCell>
                              </GridRow>
                              <GridRow>
                                <GridCell
                                  desktop={12}
                                  tablet={8}
                                  phone={4}
                                  align="middle"
                                  className={styles.hCenter}
                                >
                                  <Select<Country>
                                    name="countryId"
                                    label="Country"
                                    data={countries}
                                    idSelectFc={(i: Country) => i.id.toString()}
                                    nameSelectFc={(i: Country) => i.name}
                                    onChange={(e) => {
                                      const selectedCountryId = e.target.value;
                                      const country = countries?.find(
                                        (c) =>
                                          c.id.toString() === selectedCountryId
                                      );
                                      setFieldValue(
                                        "countryId",
                                        selectedCountryId
                                      );
                                      const stateRequired =
                                        country !== null &&
                                        country!.states !== null &&
                                        country!.states!.length > 0;
                                      if (stateRequired)
                                        setStates(country!.states);
                                      setStateRequired(stateRequired);
                                    }}
                                    className={styles.formControl}
                                    formikProps={formikProps}
                                    disabled={contactInfo !== null}
                                    required
                                  />
                                </GridCell>
                              </GridRow>
                              <GridRow>
                                <GridCell
                                  desktop={12}
                                  tablet={8}
                                  phone={4}
                                  align="middle"
                                  className={styles.hCenter}
                                >
                                  {stateRequired && (
                                    <Select<State>
                                      name="stateId"
                                      label="State"
                                      data={states}
                                      idSelectFc={(i: State) => i.id.toString()}
                                      nameSelectFc={(i: State) => i.name}
                                      formikProps={formikProps}
                                      className={styles.formControl}
                                      disabled={contactInfo !== null}
                                      required
                                    />
                                  )}
                                </GridCell>
                              </GridRow>
                              {(isNewContact() ||
                                emailRegisteredInDynamicsOnly()) && (
                                <GridRow id="rowCheckBox">
                                  <GridCell
                                    desktop={12}
                                    tablet={8}
                                    phone={4}
                                    align="middle"
                                  >                                  
                                    <Checkbox
                                      name="marketingMaterialsConsent"
                                      ref={chkGdprRef}
                                      className={`${styles.agreementCheckbox} ${styles.font12} ${styles.hLeft}`}
                                      label="I agree to receive marketing material from DHI Group through emails,
                                                text messages, social media and apps. Communication will include inspirational articles and information on products,
                                                services, training and events within the business areas of DHI Group. I am aware I can unsubscribe anytime."
                                      checked={values.marketingMaterialsConsent}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      required
                                    />
                                  </GridCell>
                                </GridRow>
                              )}
                              <GridRow>
                                <GridCell
                                  desktop={6}
                                  tablet={4}
                                  phone={2}
                                  className={`${styles.hRight} mr-1`}
                                >
                                  <StyledButton
                                    type="button"
                                    disabled={isSubmitting}
                                    className={styles.formButton}
                                    onClick={() => {
                                      if (
                                        onCancelAction === "redirect" &&
                                        cancelRedirectUrl !== undefined
                                      ) {
                                        (window.top || window).location.replace(
                                          cancelRedirectUrl
                                        );
                                      } else {
                                        if (onCancelAction === "closewin") {
                                          (window.top || window).close();
                                        } else {
                                          history.push("/login");
                                        }
                                      }
                                    }}
                                    variant="secondary"
                                  >
                                    Cancel
                                  </StyledButton>
                                </GridCell>
                                <GridCell
                                  desktop={6}
                                  tablet={4}
                                  phone={2}
                                  className={`${styles.hLeft} ml-1`}
                                >
                                  <StyledButton
                                    type="submit"
                                    className={styles.formButton}
                                    disabled={
                                      contactInfo !== null ||
                                      !emailChecked ||
                                      !captchaValidated
                                    }
                                  >
                                    Create account
                                  </StyledButton>
                                </GridCell>
                              </GridRow>
                            </>
                          )}
                          <GridRow>
                            <GridCell
                              desktop={12}
                              tablet={8}
                              phone={4}
                              align="middle"
                            >
                              <div
                                className={`${styles.privacyPolicy} ${styles.font12}`}
                              >
                                Personal data is collected and processed in
                                accordance with our&nbsp;
                                <a
                                  href="https://www.dhigroup.com/privacy"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Privacy Policy
                                </a>
                                .
                              </div>
                              <div
                                className={`${styles.privacyPolicy} ${styles.font12}`}
                              >
                                This website only uses technical necessary
                                cookies to ensure the proper functioning of the
                                site. Necessary cookies cannot be switched off
                                and are usually only set in response to requests
                                made by you, e.g., setting privacy preferences,
                                logging in or filling out forms. Necessary
                                cookies do not store any personally identifiable
                                information and your consent is not required.
                              </div>
                            </GridCell>
                          </GridRow>
                        </Grid>
                      </Form>
                    );
                }}
            </Formik>
        }

        {// when new account creation request has been sent
            processState === ProcessState.error && <>
                Error ocurred: {error ? JSON.stringify(error) : ' error not known'}...
            </>
        }
        {// when new account creation request has been sent
            processState === ProcessState.requestSent && <Progress text="We are processing your request, please wait..." />
        }
    </div>
};

export default UserRegistration;
