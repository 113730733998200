

import { ILogger } from 'utils/Logger';
import { BaseApiClient } from './BaseApiClient';
/**
 * The API client for Azure api management API (protected by subscription key)
 */
export class ApimClient extends BaseApiClient {

    APIM_SUBSCRIPTION_KEY: string;

    /**
     *
     */
    constructor(baseApiUrl: string, apimSubscriptionKey: string, logger: ILogger, correlationId: string, baseRelativeUrl?: string) {
        super(baseApiUrl, logger, correlationId, baseRelativeUrl);
        this.APIM_SUBSCRIPTION_KEY = apimSubscriptionKey;

    }

    /**
     * Adds authentication to the requests - adds Ocp-Apim-Subscription-Key header. Overrides the method in the base class
     */
    public AddAuthentication(options: RequestInit): void {

        this.AddRequestHeaders(options, [{ key: 'Ocp-Apim-Subscription-Key', value: this.APIM_SUBSCRIPTION_KEY }]);
    }

}
