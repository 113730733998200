
import { IEnvironment } from "../../Environments";
import { ServiceResponse } from "../api-common/ApiResponse";
import { ContactInfo } from "../models/ContactInfo";
import { Contact } from "../models/Contact";
import { ContactUpdate } from "../models/ContactUpdate";
import { AccountResponse, AccountRequest } from "../models/Account";
import { MikeCloudRegistrationRequest } from "../models/MikeCloudRegistrationRequest";
import { MikeCloudRegistrationResponse } from "../models/MikeCloudRegistrationResponse";
import { ILogger } from "utils/Logger";
import { GoogleRecaptchaVerificationResponse } from "services/models/GoogleRecaptchaVerificationResponse";
import { ApimClient } from "services/api-common/ApimClient";
import { ContactRequest } from "services/models/ContactRequest";

export interface ICustomersApiService {
    token?: string;
    GetContactInfo(email: string): Promise<ServiceResponse<ContactInfo>>;
    EncryptContactAndSendEmail(contact: Contact): Promise<ServiceResponse<void>>;
    UpdateContact(contactId: string, contact: ContactUpdate): Promise<ServiceResponse<string>>;
    SendEmailVerificationCode(contactId: string): Promise<ServiceResponse<void>>;
    VerifyCreateUser(request: ContactRequest, statusCodeHandlers: { [code: number]: (response: Response) => void }): Promise<ServiceResponse<ContactInfo>>;
    GetAccountsByDomain(domain: string): Promise<ServiceResponse<AccountResponse[]>>;
    CreateAccount(accountRequest: AccountRequest): Promise<ServiceResponse<string>>;
    RegisterUserInMikeCloud(registrationRequest: MikeCloudRegistrationRequest): Promise<ServiceResponse<MikeCloudRegistrationResponse>>;
    VefifyReCaptchaToken(token: string): Promise<ServiceResponse<GoogleRecaptchaVerificationResponse>>;
}

export interface ISettingsApiService {
    GetSetting(key: string): Promise<string | undefined>;
    GetSettingSection<T>(sectionName: string): Promise<ServiceResponse<T>>;
}
export class CustomersApiService implements ICustomersApiService, ISettingsApiService {
    private apiClient: ApimClient;
    private loadedSettings: { [key: string]: string } = {}
    /**
     * Constructor
     */
    constructor(env: IEnvironment, logger: ILogger, correlationId: string, public token?: string) {
        this.apiClient = new ApimClient(
            env.BS_APIM_URL,
            env.BS_APIM_SUBSCRIPTION_KEY,
            logger,
            correlationId,
            env.BS_APIM_CUSTOMERS_RELATIVE_URL);
    }


    /**
     * Checks, whether the client already exists in Dynamics and whether it's already registered in MikeCloud
     * @param email Client's email
     */
    GetContactInfo = async (email: string): Promise<ServiceResponse<ContactInfo>> => {
        return await this.apiClient.GetOf<ContactInfo>(
            `api/contacts/info/${email}`,
            "client existence info",
            r => r as ContactInfo,
            false);
    }

    /**
     * 
     * @param contact Create new contact in the Dynamics
     * @returns 
     */
    EncryptContactAndSendEmail = async (contact: Contact): Promise<ServiceResponse<void>> => {
        if (contact.stateId === '') contact.stateId = undefined;
        return await this.apiClient.PostOf<void>(
            `api/contacts`,
            contact,
            `Contact created`,
            `Contact creation failed`,
            r => r,
            false);
    }

    /**
     * @param contactId Id of contact to be updated
     * @param contact Update contact in the Dynamics
     * @returns 
     */
    UpdateContact = async (contactId: string, contact: ContactUpdate): Promise<ServiceResponse<string>> => {
        return await this.apiClient.PatchOf<string>(
            `api/contacts/${contactId}`,
            contact,
            `contact`,
            false);
    }

    /**
* 
* @param contact Ask server to send the email verification code
* @returns 
*/
    SendEmailVerificationCode = async (contactId: string): Promise<ServiceResponse<void>> => {
        return await this.apiClient.PostOf<void>(
            `api/contacts/emailverificationrequest/${contactId}`,
            {},
            `Verification code sent by email`,
            `Verification code sending failed`,
            r => r,
            false);
    }

    /**
 * 
 * @param contact Send verification details for email verification for confirmation
 * @returns 
 */
    VerifyCreateUser = async (request: ContactRequest, statusCodeHandlers: { [code: number]: (response: Response) => void } = {}): Promise<ServiceResponse<ContactInfo>> => {
        console.debug("Calling VerifyCreateUser ");

        return await this.apiClient.PostOf<ContactInfo>(
            `api/contacts/verifycreate`,
            request,
            `Verification details sent`,
            `Verification details sending failed`,
            r => r as ContactInfo,
            false,
            statusCodeHandlers
        );
    }


    /**
    * Get all available accounts by email domain
    * @param email Client's email domain (like 'dhigroup.com')
    */
    GetAccountsByDomain = async (domain: string): Promise<ServiceResponse<AccountResponse[]>> => {
        return await this.apiClient.GetOf<AccountResponse[]>(
            `api/accounts/${domain}`,
            `accounts by domain '${domain}'`,
            r => (r.value || r) as AccountResponse[],
            false);
    }

    /**
     * Creates new account in Dynamics
     * @param accountRequest The account parameters
     * @returns ID of the new account
     */
    CreateAccount = async (accountRequest: AccountRequest): Promise<ServiceResponse<string>> => {
        return await this.apiClient.PostOf<string>(
            `api/accounts`,
            accountRequest,
            `Account creation request sent`,
            `Account creation request failed`,
            r => r as string,
            false);
    }

    /**
     * Register user in the Mike Cloud
     * @param registrationRequest 
     * @returns 
     */
    RegisterUserInMikeCloud = async (registrationRequest: MikeCloudRegistrationRequest): Promise<ServiceResponse<MikeCloudRegistrationResponse>> => {
        return await this.apiClient.PostOf<MikeCloudRegistrationResponse>(
            `api/mikecloudregistrations`,
            registrationRequest,
            `Mike cloud registration request sent`,
            `Mike cloud registration request failed`,
            r => r,
            false);
    }

    /**
     * Gets single application setting
     * @param key SettingName
     */
    GetSetting = async (key: string): Promise<string | undefined> => {
        if (!key.startsWith('Customers:UI:'))
            key = `Customers:UI:${key}`;
        if (this.loadedSettings[key])
            return this.loadedSettings[key];
        var result = await this.apiClient.GetOf<string>(
            `api/config/${key}`,
            `setting '${key}'`,
            r => r as string,
            false);
        return result.response;
    }

    /**
     * Gets  application setting section with all key-values and subsections
     * @param key sectionName The name of the section - use : to separate subsections
     */
    GetSettingSection = async<T extends unknown>(sectionName: string): Promise<ServiceResponse<T>> => {

        return await this.apiClient.GetOf<T>(
            `api/config/section/${sectionName}`,
            `setting section '${sectionName}'`,
            r => r as T,
            false);
    }

    VefifyReCaptchaToken = async (token: string): Promise<ServiceResponse<GoogleRecaptchaVerificationResponse>> => {
        return await this.apiClient.GetOf<GoogleRecaptchaVerificationResponse>(
            `api/captcha?token=${token}`,
            `verifying Recaptcha token '${token}'`,
            r => r as GoogleRecaptchaVerificationResponse,
            false);
    }
}




