import React from 'react';
import styles from './Progress.module.scss';
import { CircularProgress } from '@rmwc/circular-progress';
import '@rmwc/circular-progress/styles';

export interface IProgressProps {
  text: string
}
const Progress = ({ text }: IProgressProps) => {


  return <div className={`mt-4 mb-4`}>
    <div><CircularProgress size="xlarge" /></div>
    <div className={`${styles.font14} ${styles.brandBlue}`}>{text}</div>
  </div>
};

export default Progress;
