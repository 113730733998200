import React, { useContext, useEffect, useState } from 'react';
import styles from './EmailVerification.module.scss';
import { getUrlParameter } from "../../utils/UrlUtils";
import { AppContext } from '../../AppContext';
import { useConstructor } from '../../utils/CommonHooks';
import { LinkButton } from 'components/Forms/Button';
import Progress from 'components/Progress/Progress';
import Error from '../Error/Error';
import { ContactInfo } from 'services/models/ContactInfo';
import { useAppSettings } from 'services/models/AppSettings';
import { useBroadcastChannel } from 'utils/UseBroadcastChannelHook';
import { ContactRequest } from 'services/models/ContactRequest';
export interface IEmailVerificationProps {
  onEmailVerified: (contactInfo: ContactInfo) => void;
  userId?: string;//if we are not in response, the userId must be sent here, otherwise we can not send the request verification email
  onProcessingChanged: (running: boolean) => void;
  contactRequest?: ContactRequest;
}

enum ProcessState {
  // initialized,
  invalidUrlInputs,
  checking,
  registeringUser,
  verified,
  invalidCode,
  //codeExpired,
  contactNotFound,
  verificationEmailLinkClicked,
  userCreationWithoutVerificationRequested,
  error
}

/**
 * Thi components is used for request to send email with vericode
 * as well as for checking if the verification code is correct (the components should be open when user click the link in the email)
 * To distinguish between these two states is used the property "isResponse"
 * @returns 
 */
const EmailVerification = ({ onEmailVerified, userId: userIdProp, onProcessingChanged, contactRequest: contactRequestProp }: IEmailVerificationProps) => {
  const context = useContext(AppContext);

  const [processState, setProcessState] = useState<ProcessState>();
  const [error, setError] = useState();
  const [contactInfo, setContactInfo] = useState<ContactInfo>();
  const [sendingEmail, setSendingEmail] = useState<boolean>(false);
  const { appSettings, loaded: appSettingsLoaded } = useAppSettings(context);
  const [contactRequest, setContactRequest] = useState<ContactRequest>();
  let veriCodeUrl: string | undefined;
  let userIdUrl: string | undefined;
  // let emailUrl: string | undefined;


  useEffect(() => {
    onProcessingChanged(processState === ProcessState.checking || sendingEmail);
  }, [processState, onProcessingChanged, sendingEmail])



  useConstructor(() => {
    //in response take the vericode and user id from the url
    if (contactRequestProp) {
      setContactRequest(contactRequestProp as ContactRequest);
      setProcessState(ProcessState.userCreationWithoutVerificationRequested);

    } else if (veriCodeUrl === undefined) {
      const urlParams = new URLSearchParams(document.location.search);
      veriCodeUrl = getUrlParameter(urlParams, "vericode", true, "Verification code") ?? undefined;
      userIdUrl = getUrlParameter(urlParams, "uid", true, "UserId") ?? undefined;
      if (veriCodeUrl !== undefined) {
        setContactRequest({ vericode: veriCodeUrl! });
        setProcessState(ProcessState.verificationEmailLinkClicked);
      } else {
        setProcessState(ProcessState.invalidUrlInputs);
      }


    } else {
      setProcessState(ProcessState.invalidUrlInputs);
    }

  });

  const [userId] = useState<string | undefined>(userIdUrl || userIdProp);
  //const [vericode] = useState<string | undefined>(veriCodeUrl);
  const postBroadcast = useBroadcastChannel("CustomersAppBroadcastChannel");

  /*   useEffect(() => {
      if (isResponse && !contactRequestProp)
        setProcessState(ProcessState.invalidUrlInputs);
    }, [isResponse, contactRequestProp]) */




  //if (isResponse && (!vericode || !userId)) setProcessState(ProcessState.invalidUrlInputs);°


  ///If response, verify the code 
  useEffect(() => {

    if (processState === ProcessState.verificationEmailLinkClicked ||
      processState === ProcessState.userCreationWithoutVerificationRequested) {
      let success = true;
      const statusCodeHandlers: { [code: number]: (response: Response) => void } = {
        /*         201: (response: Response) => {
                  //new user was created
                  response.json().then((json) => {
                    const contactInfo: ContactInfo = { ...json } as ContactInfo;
                    setContactInfo(contactInfo);
                    setProcessState(ProcessState.verified);
                  })
                },
                204: (response: Response) => {
                  //existing user was verified
                  response.json().then((json) => {
                    const contactInfo: ContactInfo = { ...json } as ContactInfo;
                    setContactInfo(contactInfo);
                    setProcessState(ProcessState.verified);
                  })
        
                }, */
        400: () => { setProcessState(ProcessState.invalidCode); success = false; },
        404: () => { setProcessState(ProcessState.contactNotFound); success = false; },
        //408: () => { setProcessState(ProcessState.codeExpired); },
      }

      context.customersApiService!.VerifyCreateUser(contactRequest!, statusCodeHandlers)
        .then((response) => {
          if (success) {
            const contactInfo: ContactInfo = response.response;
            setContactInfo(contactInfo);
            setProcessState(ProcessState.verified);
            postBroadcast({
              correlationId: context.correlationId,
              name: 'userEmailVerified'
            });

          }
        });
      if (processState === ProcessState.verificationEmailLinkClicked)
        setProcessState(ProcessState.checking);
      else
        setProcessState(ProcessState.registeringUser);

    } else {

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactRequest, processState])

  //call the callback when the user is verified
  useEffect(() => {
    if (processState === ProcessState.verified)
      onEmailVerified(contactInfo!);

  }, [onEmailVerified, processState, contactInfo])

  const SendEmailLinkButton = (text: string) => {
    return <LinkButton
      type="button"
      onClick={(e) => {
        context.customersApiService?.SendEmailVerificationCode(userId!)
          .then(() => {
            setProcessState(ProcessState.verificationEmailLinkClicked);
            setSendingEmail(false);
          })
          .catch((error) => {
            setError(error);
            setProcessState(ProcessState.error);
            setSendingEmail(false);
          });
        setSendingEmail(true);

      }} >{text}</LinkButton>;
  }

  return <div className={styles.EmailVerification}>

    {// this is reached when user click the link in email
      <>
        {processState === ProcessState.checking && <Progress text="Checking the verification link..." />}
        {processState === ProcessState.registeringUser && <Progress text="We are registering you in our system..." />}

        {/* in case of any error */}
        {(processState === ProcessState.invalidUrlInputs ||
          //processState === ProcessState.codeExpired ||
          processState === ProcessState.invalidCode ||
          processState === ProcessState.contactNotFound) &&
          <div>
            {processState === ProcessState.invalidUrlInputs && <Error title="Url invalid" description="The url you used to get here is invalid.">
              <div className={`${styles.font14} ${styles.brandBlue}`}>Please, copy paste the complete url from your email into your browser manually. If the problem persists, please contact the <a href={`mailto:${appSettingsLoaded && appSettings?.hotlineEmail}`}>DHI hotline</a>.</div>
            </Error>}
            {/*             {processState === ProcessState.codeExpired && <Error title="Code expired" description="The verification code already expired">
              <div className={`${styles.font14} ${styles.brandBlue}`}>Please, {SendEmailLinkButton("send an email with a new code again")}. If the problem persists, please contact the <a href={`mailto:${appSettingsLoaded && appSettings?.hotlineEmail}`}>DHI hotline</a>.</div>
            </Error>} */}
            {processState === ProcessState.contactNotFound && <Error title="Contact not found" description="The contact with ID from the url was not found">
              <div className={`${styles.font14} ${styles.brandBlue}`}>Please, {SendEmailLinkButton("send an email with a new code again")}. If the problem persists, please contact the <a href={`mailto:${appSettingsLoaded && appSettings?.hotlineEmail}?subject=${context.correlationId}`}>DHI hotline</a>.</div>
            </Error>}
            {processState === ProcessState.invalidCode && <Error title="Code is invalid" description="The verification code from the url is invalid">
              <div className={`${styles.font14} ${styles.brandBlue}`}>Please, {SendEmailLinkButton("send an email with a new code again")}. If the problem persists, please contact the <a href={`mailto:${appSettingsLoaded && appSettings?.hotlineEmail}?subject=${context.correlationId}`}>DHI hotline</a>.</div>
            </Error>}
          </div>
        }
      </>}

    {/* {// this is reached when user should initialize to send the verification code by email
      !isResponse && <>

        {processState === ProcessState.initialized && <div className={`${styles.font14} ${styles.brandBlue}`}>
          In order to verify your email address, we will send you an email with code. Please, {SendEmailLinkButton("click here")} to receive an email with the verification code.
        </div>}
      </>} */}

    {processState === ProcessState.verificationEmailLinkClicked && <div className={`${styles.font14} ${styles.brandBlue}`}>
      <div>To verify your email address, please follow the instructions and use the verification link sent to your email. The link will expire in 1 hour.</div>
      <div> Didn’t receive the email? Please, check your spam folder or {SendEmailLinkButton("send the email again")}</div>

    </div>}

    {// when new account creation request has been sent
      processState === ProcessState.error && <>
        Error occured: {error}...
      </>
    }


    {/*     {(!isResponse ||
      processState === ProcessState.codeExpired ||
      processState === ProcessState.invalidCode ||
      processState === ProcessState.verificationEmailSent) && <div className={`${styles.hCenter} mb-4 mt-4`}>
        <StyledButton onClick={() => {
          context.customersApiService?.SendEmailVerificationCode(userId!)
            .then(() => {
              setProcessState(ProcessState.verificationEmailSent);
              setSendingEmail(false);
            })
            .catch((error) => {
              setError(error);
              setProcessState(ProcessState.error);
              setSendingEmail(false);
            });
          setSendingEmail(true);

        }} >Send email with verification code</StyledButton>
      </div>} */}
    {// when sending email
      sendingEmail && <Progress text='Email is being sent to you...' />
    }


  </div>;
}

export default EmailVerification;

