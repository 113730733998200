import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { useState } from "react";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useConstructor } from './utils/CommonHooks';
import { getUrlParameter } from "./utils/UrlUtils";

import { CustomersApiService, ICustomersApiService } from "./services/api/CustomersApiService";
import "./styles/App.css";

import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { Suspense } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { ai, getAppInsights } from 'utils/AppInsights';
import { Logger } from "utils/Logger";
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from "./AppContext";
import { Environments, IEnvironment } from "./Environments";
import ClientRegistration from "./components/ClientRegistration/ClientRegistration";
import { BsApimService, IBsApimService } from "./services/api/BsApimService";
import { } from "./utils/StringExtensions";

import { ThemeProvider, createTheme } from '@mui/material/styles';

import ExternalProgress from './components/ExternalProgress/ExternalProgress';
import FinishedPage from './components/FinishedPage/FinishedPage';
//import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
//import SignalRMsg from './SignalRMsg';
import { AppSettings, IAppSettings } from "services/models/AppSettings";



import AccountCreation from "components/AccountCreation/AccountCreation";
import DialogContainer from "components/DialogContainer/DialogContainer";
import NotRegisteredInfo from "components/NotRegisteredInfo/NotRegisteredInfo";
import PageCanBeClosedPage from "components/PageCanBeClosedPage/PageCanBeClosedPage";
import SignIn from "components/SignIn/SignIn";
import { BroadcastChannelEvent } from "services/models/BroadcastChannelEvent";
import { useBroadcastChannel } from "utils/UseBroadcastChannelHook";

const theme = createTheme({
    components: {

        MuiTextField: {
            styleOverrides: {
                root: {
                    fontWeight: 'bold',
                    color: '#006543',
                    "&.Mui-focused": {
                        color: '#00A3EA',
                    },
                },
            },
            defaultProps: {
                variant: "outlined"
            },
        },

    },

});


const App = () => {

    const [isInDebugMode, setIsInDebugMode] = useState<boolean>();
    const [customersApiService, setCustomersApiService] = useState<ICustomersApiService>();
    const [bsApimService, setBsApimService] = useState<IBsApimService>();
    const [environment, setEnvironment] = useState<IEnvironment>();
    const [correlationId, setCorrelationId] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [appInsights, setAppInsights] = useState<ApplicationInsights>();
    const [redirectUrl, setRedirectUrl] = useState<string>('');
    const [redirectLinkText, setRedirectLinkText] = useState<string>('');
    const [logger, setLogger] = useState<Logger>();
    //const [signalRHubConnection, setSignalRHubConnection] = useState<null | HubConnection>(null);
    const [appSettings, setAppSettings] = useState<IAppSettings>();
    const [onCancelAction, setOncancelAction] = useState<string | undefined>(undefined);
    const [cancelRedirectUrl, setCancelRedirectUrl] = useState<string | undefined>(undefined);
    const [contactCode, setContactCode] = useState<string | undefined>(undefined);
    //const [bcChannel, setBcChannel] = useState<BroadcastChannel>();
    const history = useHistory();

    useConstructor(() => {

        console.debug("App constructor called...");

        const urlParams = new URLSearchParams(document.location.search);
        const defaultEnvironment = document.location.hostname.includes("customer.dhigroup.com") ? 'live' :
            document.location.hostname.includes("test") ? 'test' :
                'dev';

        console.log(`From the url it seems, that you are in '${defaultEnvironment}' environment.`);
        const env = getUrlParameter(urlParams, "env", false, "Environment", defaultEnvironment);//|| 'dev'; //dev|live

        //-------------  IS DEBUG  -------------------------------------------------------------

        const inDebug = getUrlParameter(urlParams, "debug", false, "Debug mode", 'false');
        const isInDebug = inDebug ? inDebug.toUpperCase() === "TRUE" : false;


        //-------------  EMAIL  -------------------------------------------------------------
        const email: string = getUrlParameter(urlParams, "email", false, "Email", "") || "";


        //------------    CORRELATION ID --------------------------------------------------
        const corid = getUrlParameter(urlParams, "corid", false, "Correlation id", uuidv4());
        console.log('========================================================================');
        console.log('CORRELATION-ID: ' + corid);
        console.log('========================================================================');

        //-------------  ENVIRONMENT  -------------------------------------------------------------
        const environment = env ? Environments[env] : undefined;
        if (!environment) {
            throw Error(`No environment is defined for sent environment id '${env}'. Use one of these dev|prod|local (note, that local will work only, if you run the web api locally at specific address - just for developers with the code!!)`)
        }

        //------------    REDIRECT URL --------------------------------------------------
        let redirectUrl = getUrlParameter(urlParams, "redir", false, "RedirectUrl", environment.DEFAULT_REDIR_URL)!;
        if (redirectUrl && redirectUrl.length > 0) {
            const url = new URL(redirectUrl!);
            const params = url.searchParams;
            console.log(params.toString());
            const encodedParams = encodeURIComponent(params.toString());
            redirectUrl = `${redirectUrl.split('?')[0]}?${encodedParams}`;

        }
        console.log(`Escaped redirect url=${redirectUrl}`);

        //-------------   REDIRECT TEXT  -------------------------------------------------------------
        const redirLinkText = getUrlParameter(urlParams, "redirtxt", false, "Redirect link text", environment.DEFAULT_REDIR_TEXT)!;

        //-------------   ON CANCEL    ------------------------------------------------------------------
        const onCancelAction = getUrlParameter(urlParams, "oncancel", false, "Action to be done when registration is canceled (undefined|'closewin'|'redirect')", undefined)!;
        const cancelRedirectUrl = getUrlParameter(urlParams, "oncancelredir", false, "Redirect url when registration is canceled (onCancelAction must be set to 'redirect')", undefined)!;
        console.log(`onCancelAction=${onCancelAction}`);

        //-------------   contact code  -------------------------------------------------------------
        const contactCode = getUrlParameter(urlParams, "contactcode", false, "Contact code", undefined)!;

        //-------------  Application insights  -------------------------------------------------------------

        ai.initialize(environment);
        const appInsights = getAppInsights();
        var logger = new Logger(appInsights, corid!, isInDebug);

        var customerApi = new CustomersApiService(environment, logger, corid!);
        var bsApim = new BsApimService(environment, logger, corid!);

        var appSettings = new AppSettings(customerApi);

        //-------------  SIGNAL R  -------------------------------------------------------------
        /*         //SignalR - used for closing windows - it sends messages to previously opened window to close themselves
                const signalRhubUrl = `${environment.CUSTOMERS_API_URL}/hubs/events`;
                console.log(`SignalR hub url: '${signalRhubUrl}''. Opening it... `)
                const connect = new HubConnectionBuilder()
                    .withUrl(signalRhubUrl)
                    .withAutomaticReconnect()
                    .build();
        
                setSignalRHubConnection(connect);
                console.log(`SignalR hub defined.`)
                //end of SignalR */

        setIsInDebugMode(isInDebug);
        setCustomersApiService(customerApi);
        setBsApimService(bsApim);
        setEnvironment(environment);
        setCorrelationId(corid!);
        setAppInsights(appInsights);
        setLogger(logger);
        setRedirectUrl(redirectUrl);
        setRedirectLinkText(redirLinkText);
        setAppSettings(appSettings);
        setEmail(email);
        setCancelRedirectUrl(cancelRedirectUrl);
        setOncancelAction(onCancelAction);
        setContactCode(contactCode);
    });

    const postBroadcast = useBroadcastChannel("CustomersAppBroadcastChannel", (e) => {
        console.log(`Received data from CustomersAppBroadcastChannel : ${JSON.stringify(e.data)}, version 13.9.`);
        const evt = e.data as any as BroadcastChannelEvent;
        if (evt.correlationId === correlationId) {
            if (evt.name === "userEmailVerified" && document.location.href.includes("registration") && contactCode === undefined) {
                console.log(`Closing 'clientreg' window...`);
                window.opener = null;
                window.open('', '_self');
                (window.top || window).close();

                history.push("/pageCanBeClosed");
            }
            else {
                if (evt.name === "mikeCloudIvitationSent" && document.location.href.includes("emailverification")) {
                    console.log(`Closing 'emailverification' window...`);
                    window.opener = null;
                    window.open('', '_self');
                    (window.top || window).close();
                }
            }
        } else {
            console.log(`evt.correlationId  (${evt.correlationId}) != correlationId(${correlationId})...`);
        }
    });
    /*     useEffect(() => {
            const bc = new BroadcastChannel('CustomersAppBroadcastChannel');
            bc.onmessage = event => {
    
                const evt = event as any as BroadcastChannelEvent;
                if (evt.correlationId === correlationId) {
                    if (evt.name === "userEmailVerified" && document.location.href.includes("registration")) {
                        console.log(`Closing 'clientreg' window...`);
                        window.close();
                    }
                    else {
                        if (evt.name === "mikeCloudIvitationSent" && document.location.href.includes("emailverification")) {
                            console.log(`Closing 'emailverification' window...`);
                            window.close();
                        }
                    }
                }
    
            }
            setBcChannel(bc);
    
        }, [correlationId]); */
    /* useEffect(() => {
        if (signalRHubConnection && signalRHubConnection.state === "Disconnected") {
            signalRHubConnection
                .start()//starts connection to the backend's SignalR hub
                .then(() => {
                    // Close the browser tab, when user clicks the link in the email verification email and when the email has been verified
                    // this method react on the message sent from the backend by SignalR
                    signalRHubConnection.on("EventOccured", (message: SignalRMsg) => {
                        console.log(`SignalR message received '${JSON.stringify(message)}'`)
                        if (message.correlationId === correlationId) {
                            if (message.event === "userEmailVerified" && document.location.href.includes("registration")) {
                                console.log(`Closing 'clientreg' window...`);
                                window.close();
                            }
                            else {
                                if (message.event === "mikeCloudIvitationSent" && document.location.href.includes("emailverification")) {
                                    console.log(`Closing 'emailverification' window...`);
                                    window.close();
                                }
                            }
                        }
                    });
                })
                .catch((error) => {
                    console.log("Unable start the hub.");
                    console.log(error);
                })

        }
    }, [signalRHubConnection, correlationId]); */

    return (
        <AppInsightsErrorBoundary onError={() => <h1>Something went wrong, check the App insights</h1>} appInsights={ai.reactPlugin} >
            <AppContext.Provider value={
                {
                    isInDebugMode: isInDebugMode!,
                    environment: environment!,
                    bsApimService: bsApimService,
                    customersApiService: customersApiService,
                    correlationId: correlationId,
                    appInsights: appInsights!,
                    logger: logger!,
                    appSettings: appSettings!
                }} >

                <div className="App">
                    {/*  <Theme use={['primaryBg', 'onPrimary']} wrap> */}
                    <ThemeProvider theme={theme}>
                        <GoogleReCaptchaProvider reCaptchaKey="6Ldz8YkdAAAAAJbSO-bnyagAvGlAivksW1PwD92G"                        >
                            {/*  browser router must be defined one level above (in index.html) to enable to use history in this component */}
                            {/* <BrowserRouter> */}
                            <Suspense fallback={<div>Loading...</div>}>
                                <Switch>

                                    <Route exact path="/" render={() => <Redirect to="/login" />} />
                                    <Route exact path="/pageCanBeClosed" render={() => <PageCanBeClosedPage />} />
                                    <Route exact path="/login" render={() =>
                                        <SignIn
                                            afterRegistrationRedirectUrl={redirectUrl}
                                            afterRegistrationRedirectLinkText={redirectLinkText} />} />
                                    <Route exact path="/notregistered" render={() =>
                                        <NotRegisteredInfo
                                            afterRegistrationRedirectUrl={redirectUrl}
                                            afterRegistrationRedirectLinkText={redirectLinkText} />} />
                                    <Route exact path="/clientreg" render={() => <Redirect to="/registration" />} />
                                    <Route exact path="/registration" render={() =>

                                        <ClientRegistration
                                            afterRegistrationRedirectUrl={redirectUrl}
                                            afterRegistrationRedirectLinkText={redirectLinkText}
                                            cancelRedirectUrl={cancelRedirectUrl}
                                            onCancelAction={onCancelAction}
                                            email={email}
                                            contactCode={contactCode} />

                                    } />

                                    {/* Shown when user clicks the link in the verification email */}
                                    <Route exact path="/emailverification" render={() => <ClientRegistration waitForVerificationResult={true} />
                                    } />


                                    <Route exact path="/progress" render={() =>
                                        <ExternalProgress text="We are processing your request, please wait..." />
                                    } />

                                    <Route exact path="/finished" render={() => {
                                        try {
                                            //if (signalRHubConnection && signalRHubConnection.state === "Connected") {
                                            /*                                                console.log("Sending signalR message 'mikeCloudIvitationSent'...");
                                                                                           const msg: SignalRMsg = {
                                                                                               correlationId: correlationId,
                                                                                               event: "mikeCloudIvitationSent"
                                                                                           }
                                                                                           signalRHubConnection.send("SendMessage", msg);
                                                                                           console.log("SignalR message sent "); */
                                            console.log("Sending Broadcast channel message 'mikeCloudIvitationSent'...");
                                            postBroadcast({
                                                correlationId: correlationId,
                                                name: 'mikeCloudIvitationSent'
                                            });
                                            console.log("Broadcast channel message sent ");
                                            /*                                                 } else {
                                                                                                console.warn("Cannot send signalR message, because the connection is not open.");
                                                                                            } */
                                        } catch (error) {
                                            console.error(`Unable to send SignalR message 'mikeCloudIvitationSent', ${error}`);
                                        }

                                        return <FinishedPage url={redirectUrl || undefined} linkText={redirectLinkText || undefined} />
                                    }
                                    } />

                                </Switch>
                            </Suspense>
                            {/* </BrowserRouter> */}
                        </GoogleReCaptchaProvider>
                    </ThemeProvider>
                    {/* </Theme> */}
                </div>

            </AppContext.Provider>
        </AppInsightsErrorBoundary >

    );
};

export default App;


