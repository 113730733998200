import React, { useEffect, useRef, useState } from 'react';
import styles from './FinishedPage.module.scss';
import DialogContainer from '../DialogContainer/DialogContainer';
import { useLocation } from 'react-router-dom';
import { useConstructor } from 'utils/CommonHooks';

export interface IFinishedPageProps {
  url?: string,
  linkText?: string;
}

export interface LocationState {
  redir: string;
  redirtxt: string;
}

const timeSec = 10;

const FinishedPage = ({ url: urlProd, linkText: linkTextProd }: IFinishedPageProps) => {

  const location = useLocation<LocationState>();
  const [secsToAutoredirect, setSecsToAutoredirect] = useState(timeSec)
  const [linkText, setLinkText] = useState(linkTextProd);
  const [url, setUrl] = useState(urlProd);
  const urlRef = useRef(url);
  urlRef.current = url;

  useConstructor(() => {
    const { redir, redirtxt } = (location.state as LocationState) || { redir: "", redirtxt: "You can continue here." };
    const linkTxt = linkTextProd || redirtxt;
    setLinkText(decodeURIComponent(linkTxt));

    let linkUrl = urlProd || redir;
    console.log(`Encoded URI: ${linkUrl}`);
    linkUrl = decodeURIComponent(linkUrl);
    console.log(`Decoded URI: ${linkUrl}`);
    setUrl(linkUrl);


  })

  // ==========================================
  //   Interval countdown
  // ==========================================
  useEffect(() => {
    console.log("setting interval");
    secsToAutoredirect > 0 && setTimeout(() => setSecsToAutoredirect(secsToAutoredirect - 1), 1000);
  }, [secsToAutoredirect]);


  // ==========================================
  //   Redirect automatically after given time
  // ==========================================
  useEffect(() => {

    setTimeout(() => {
      const urlRedir = urlRef.current;
      console.log(`Redirecting to ${urlRedir}`);

      (window.top || window).location.href = urlRedir!;
    }, timeSec * 1000);

  }, [url]);

  const linkClose = `<a href='${url}' target='_top' onclick='window.open("${url}");(window.top || window).close();return false;'>${linkText}</a>`;

  return <DialogContainer title="Registration completed" showTitle={true} showProgressBar={true} showCross={false} stepsCount={5} selectedStepNr={5}>

    <div className={`${styles.font14} ${styles.brandBlue} mt-4 mb-4`}>

      <div>
        <span>You have successfully created your account. </span> {(url && url!.length > 0) &&
          <span dangerouslySetInnerHTML={{ __html: `${linkClose}` }} ></span>}
      </div >
      <div className={`mt-4 ${styles.bold}`}>
        Thank you!
      </div>
      <div className={`mt-4 ${styles.redirectInfo}`}>
        <span>You will be automatically redirected in <b>{secsToAutoredirect}</b> seconds (if it's not blocked by your browser).</span>

      </div>
    </div>
  </DialogContainer >

};

export default FinishedPage;
