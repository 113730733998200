import React, { ReactNode, useContext, useEffect, useState } from 'react';
import styles from './DialogContainer.module.scss';
import { Grid, GridRow, GridCell } from '@rmwc/grid';
import '@rmwc/grid/styles';
import '@rmwc/icon/styles';
//import logo from '../../images/dhi_logo_dhinet.png';
import ProgressTracker from 'components/ProgressTracker/ProgressTracker';

import { Icon } from '@rmwc/icon';
import { IAppSettings, useAppSettings } from 'services/models/AppSettings';
import { AppContext } from 'AppContext';

export interface IDialogContainerProps {
  stepsCount?: number;
  selectedStepNr?: number;
  title?: string;
  showTitle?: boolean;
  showProgressBar?: boolean;
  showCross?: boolean;
  topRowContent?: ReactNode;
}

interface bcgImageLocalStorage {
  index: number;
  expiration: Date;
}

const getBgImageUrl = (appSettings: IAppSettings) => {
  const bgImages = appSettings.bcgImages;
  //var bgStoredIndex = ls.get<bcgImageLocalStorage>('bgImgIndex');

  var bgIndexLocalStorage = localStorage.getItem('bgImgIndex');
  var bgStoredIndex: bcgImageLocalStorage = bgIndexLocalStorage ? JSON.parse(bgIndexLocalStorage) : null;

  const now = new Date();
  let bgIndex = 0;
  if (bgStoredIndex && new Date(bgStoredIndex.expiration) >= now) {

    console.debug(`Using bgImgIndex= ${bgStoredIndex} from local storage`);
    bgIndex = bgStoredIndex.index;

  } else if (appSettings) {
    console.debug(`BgImgIndex not found in the local storage, generating one as random...`);
    bgIndex = (~~(Math.random() * bgImages.length));
    const bgExpirationIntervalMinutes = appSettings ? appSettings.bgExpirationIntervalMinutes : 60;
    console.debug(`Expiration time in minutes = ${bgExpirationIntervalMinutes}.`);
    const expirationTime = new Date(new Date(now).setMinutes(now.getMinutes() + bgExpirationIntervalMinutes));
    localStorage.setItem('bgImgIndex', JSON.stringify({ index: bgIndex, expiration: expirationTime.toUTCString() }));
    console.debug(`bgIndex and expiration time written to the local storage`);
  }
  //const randomBcgIndex = appSettingsLoaded ? (~~(Math.random() * bgImages.length)) : 0;// Math.floor(Math.random() * (appSettings?.bcgImages.length! - 1)) : 0;
  const bcgUrl = bgImages[bgIndex];
  console.debug(`user bcg url = '${bcgUrl}', randomBcgIndex=${bgIndex}`);
  return bcgUrl;
}



const DialogContainer: React.FC<IDialogContainerProps> = ({ title, children, stepsCount, selectedStepNr, showTitle: showTitleProp,
  showProgressBar: showProgressBarProp, showCross: showCrossProp, topRowContent }) => {

  const showTitle = showTitleProp !== null ? showTitleProp : true;
  const showProgressBar = showProgressBarProp !== null ? showProgressBarProp : true;
  const showCross = showCrossProp !== null ? showCrossProp : true;

  const context = useContext(AppContext);
  const { appSettings, loaded: appSettingsLoaded } = useAppSettings(context);
  const [bcgUrl, setBcgUrl] = useState<string>('');
  const [logo, setLogo] = useState<string>();

  useEffect(() => {
    //=====================   BCG image  ==============================
    if (appSettingsLoaded) {
      setBcgUrl(getBgImageUrl(appSettings!));
      setLogo(appSettings!.DhiLogoUrl);
    }


    //=====================   end BCG image  ==============================
  }, [appSettingsLoaded, appSettings, setBcgUrl])

  return <div className={styles.dialogContainer} style={{ backgroundImage: `url(${bcgUrl})`, backgroundSize: 'cover' }}>
    <Grid className={styles.dialogBox}>
      <GridRow className={styles.topRow}>
        {topRowContent || <>
          <GridCell desktop={12} tablet={8} phone={4} ><img className={styles.logo} src={logo} alt="DHI logo" /></GridCell>
          {showCross && <GridCell span={1} align="middle"><Icon icon="close" /></GridCell>}
        </>}

      </GridRow>
      {showProgressBar && <GridRow>
        {/* <GridCell span={1}></GridCell> */}
        <GridCell desktop={12} tablet={8} phone={4} className={styles.hCenter}><ProgressTracker stepsCount={stepsCount} selectedStepNr={selectedStepNr} /></GridCell>
      </GridRow>}
      {showTitle && <GridRow>
        <GridCell desktop={12} tablet={8} phone={4}> <span className={styles.title}>{title}</span></GridCell>
      </GridRow>}
      {/* content */}
      <GridRow className="pl-2 pr-2 w100">
        <GridCell desktop={12} tablet={8} phone={4}> {children}</GridCell>
      </GridRow>
    </Grid>
  </div>
};

export default DialogContainer;
