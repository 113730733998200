import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import styles from './AccountAssociation.module.scss';
import { AccountResponse } from '../../services/models/Account';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import AccountCreation from 'components/AccountCreation/AccountCreation';
import { Select } from 'components/Forms/Select/Select';
import { Grid, GridCell, GridRow } from '@rmwc/grid';
import { ButtonGroup } from '../Forms/ButtonGroup/ButtonGroup';
import Progress from 'components/Progress/Progress';
import { LinkButton } from 'components/Forms/Button';
import { ServiceResponse } from 'services/api-common/ApiResponse';
import { MikeCloudRegistrationResponse } from 'services/models/MikeCloudRegistrationResponse';
import { useAppSettings } from 'services/models/AppSettings';

export interface IAccounsAssociationProps {
  contactId: string;
  userEmail: string;
  accountAssociated: boolean;
  onProcessingChanged: (running: boolean) => void;
  onMikeCloudRegistrationSucceeded: () => void;
  onExistingMikeCloudUserPaired: (redirUrl: string, redirText: string) => void;
}

enum ProcessState {
  initialized,
  companyLoading,
  companySelectionRequested,
  companiesLoaded,
  mikeCloudRegistrationRequested,
  mikeCloudRegistrationRunning,
  mikeCloudRegistrationSucceeded,
  mikeCloudRegistrationFailed,
  userRequestedNewCompanyCreation
}


function filterComaniesWithDisplayName(o: AccountResponse) {
  return (o.registeredName != null || o.name != null) &&
    (o.city != null || o.country != null);
}


const AccountAssociation = ({ userEmail, contactId, accountAssociated,
  onProcessingChanged, onMikeCloudRegistrationSucceeded, onExistingMikeCloudUserPaired }: IAccounsAssociationProps) => {

  const context = useContext(AppContext);  
  const [processState, setProcessState] = useState<ProcessState>(accountAssociated ?
    //ProcessState.mikeCloudRegistrationSucceeded :
    ProcessState.mikeCloudRegistrationRequested :
    ProcessState.companySelectionRequested);
  //ProcessState.mikeCloudRegistrationFailed);
  const [accounts, setAccounts] = useState<AccountResponse[]>([]);
  const [accountId, setAccountId] = useState<string>();
  const { appSettings, loaded: appSettingsLoaded } = useAppSettings(context);
  // const [hotlineEmail, setHotlineEmail] = useState<string>();

  const displayCompanyName = (o: AccountResponse) => {
    const name = o.registeredName || o.name;
    let location;
    if (o.city != null && o.country != null) {
      location = `(${o.city}, ${o.country})`;
    } else if (o.country != null) {
      location = `(${o.country})`;
    } else if (o.city != null) {
      location = `(${o.city})`;
    }
    if (location) {
      return `${name} ${location}`;
    }
    return name;
        
  };

  /// Company selected
  useEffect(() => {
    onProcessingChanged(processState === ProcessState.companySelectionRequested || processState === ProcessState.mikeCloudRegistrationRunning);
    switch (processState) {
      // when user decided, that he wants to be associated with a company - list of companies must be loaded then
      case ProcessState.companySelectionRequested: {
        const GetCompanies = async () => {
          const { customersApiService: customersAPI } = context;
          const domain = userEmail.split("@")[1];
          const companiesResponse = await customersAPI!.GetAccountsByDomain(domain);
          let companies = companiesResponse.response.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
          // filter companies that have a name we can show
          companies = companies.filter(filterComaniesWithDisplayName);
          setAccounts(companies);
          setProcessState(ProcessState.companiesLoaded);
        }
        GetCompanies();

        break;
      }
      // when Mike cloud registration was requested
      case ProcessState.mikeCloudRegistrationRequested: {

        const { customersApiService: customersAPI } = context;
        customersAPI?.RegisterUserInMikeCloud({ contactId: contactId!, accountId: accountId! })
          .then((result: ServiceResponse<MikeCloudRegistrationResponse>) => {
            if (result.response.registrationResult === "existingUserPaired") {
              //user exists in MikeCloud and is paired with the Dynamics contact - external identity record exists
              console.log(`User exists in MikeCloud and is paired with the Dynamics contact - external identity record exists.`);
              onExistingMikeCloudUserPaired(result.response.redirUrl, result.response.redirText);

            } else if (result.response.registrationResult === "existingUserNotPaired") {

              //user exists in MikeCloud, but he is not paired with the Dynamics contact - external identity record doesn't exists
              console.log(`User exists in MikeCloud, but he is not paired with the Dynamics contact - external identity record doesn't exists. Redirecting to ${result.response.inviteUrl}`);
              (window.top || window).location.replace(result.response.inviteUrl);

            } else {

              //registration to mike cloud has been called
              setProcessState(ProcessState.mikeCloudRegistrationSucceeded);
              onMikeCloudRegistrationSucceeded();
            }
          })
          .catch((e) => {
            console.error(e);
            setProcessState(ProcessState.mikeCloudRegistrationFailed);
          })
        setProcessState(ProcessState.mikeCloudRegistrationRunning);

        break;
      }
      /*       // when Mike cloud registration was requested
            case ProcessState.mikeCloudRegistrationFailed: {
      
              context.customersApiService!.GetSetting('hotlineEmail')
                .then((value: string | undefined) => {
                  if (value)
                    setHotlineEmail(value);
                });
      
            } */

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processState, onProcessingChanged])


  return <div className={styles.AccountAssociation}>


    {// when user want to be associated with the company - list of companies is loading
      processState === ProcessState.companySelectionRequested && <Progress text="Loading the list of potential company associations..." />
    }


    {// when companies loaded
      processState === ProcessState.companiesLoaded && <>
        {accounts.length > 0 && <>
          <p className={`${styles.font14} ${styles.brandBlue}`}>We found companies you may be associated with using your email address. Please, select one of the following companies, or create a new one:
          </p>
          <Formik
            initialValues={{ accountId: '' }}
            onSubmit={(values, actions) => {
              // user selected company              
              setAccountId(values.accountId);
              setProcessState(ProcessState.mikeCloudRegistrationRequested);
              actions.setSubmitting(false);
            }}
            validationSchema={Yup.object().shape({
              accountId: Yup.string().required('Required')
            })}
          >
            {(formikProps: FormikProps<any>) => {

              return <Form>
                <Grid>
                  <GridRow>                    
                    <GridCell desktop={12} tablet={8} phone={4} align='middle' className={styles.hCenter}>
                      <Select<AccountResponse>
                        name="accountId"
                        label="Select a company"
                        data={accounts}
                        idSelectFc={(i: AccountResponse) => i.accountId!.toString()}
                        nameSelectFc={displayCompanyName}
                        formikProps={formikProps}
                        className={styles.formField}
                        required />

                    </GridCell>
                  </GridRow>
                  <GridRow>
                    <GridCell desktop={12} tablet={8} phone={4} align='middle' >
                      <ButtonGroup buttonConfigs={[
                        // "Don't associate me" is temporarily removed (2024/02/09 - requested by TOP)
                        // { text: "Don't associate me", props: { variant: 'secondary' }, onClick: () => { setProcessState(ProcessState.mikeCloudRegistrationRequested) } },
                        { text: "Associate me", props: { variant: 'primary' }, type: 'submit' },//, disabled: isSubmitting || !isValid || !values.accountId },
                        { text: "Associate me with new company", props: { variant: 'secondary' }, onClick: () => { setProcessState(ProcessState.userRequestedNewCompanyCreation) } }
                      ]}></ButtonGroup>
                    </GridCell>
                  </GridRow>
                </Grid>
              </Form>
            }
            }
          </Formik>

        </>}

        {accounts.length === 0 && <>
          <p className={`${styles.font14} ${styles.brandBlue}`}>
            We didn't find any existing company in our system you can connect to.
            This search was based on your email address.
            If you know your company already exists and you want to connect to it, please contact your company administrator.
            Otherwise you can create a new company.
          </p>

          <ButtonGroup className="mb-5" buttonConfigs={[
            // "Don't associate me" is temporarily removed (2024/02/09 - requested by TOP)
            // { text: "Don't associate me", props: { variant: 'secondary' }, onClick: () => { setProcessState(ProcessState.mikeCloudRegistrationRequested) } },
            { text: "Associate me with new company", props: { variant: 'secondary' }, onClick: () => { setProcessState(ProcessState.userRequestedNewCompanyCreation) } }
          ]}></ButtonGroup>

        </>}
      </>
    }

    {// new company creation
      processState === ProcessState.userRequestedNewCompanyCreation && <>
        <AccountCreation
          contactEmail={userEmail}
          onAccountCreated={(accountId: string) => {
            setAccountId(accountId);
            setProcessState(ProcessState.mikeCloudRegistrationRequested);
          }}
          onCanceled={() => {
            setProcessState(ProcessState.companiesLoaded);
          }}
        />
      </>
    }

    {// on component initialization
      processState === ProcessState.mikeCloudRegistrationRunning && <Progress text="We are processing your request..." />
    }

    {// on mike cloud registration succeeded
      processState === ProcessState.mikeCloudRegistrationSucceeded && <div className={`${styles.font14} ${styles.brandBlue} ${styles.textCenter} mt-5 mb-5`}>
        <div>Your registration passed successfully. The invitation email has been sent to you.
          Please check your email and follow the instructions there to finish the process and log in to the DHI environment.</div>
        <div className={`${styles.bold} mt-4`}>You can <LinkButton type="button"
          onClick={(e) => {
            console.log(`Closing window...`);
            window.close();
            return false;

          }} >close this window</LinkButton> now.
        </div>

      </div>
    }

    {// on mike cloud registration failed
      processState === ProcessState.mikeCloudRegistrationFailed && <div className={`${styles.vCenter} mt-5 mb-5`}>
        {/*   <Icon icon={{ icon: 'error', style: { color: "darkred" } }} /> */}
        <p className={`${styles.font14} ${styles.brandBlue}`}>Unfortunately, your registration failed. Please contact <a href={`mailto:${appSettingsLoaded && appSettings?.hotlineEmail}`}>DHI hotline</a> for support. <br />We apologize for the inconvenience.</p>


      </div>
    }


  </div>
}

export default AccountAssociation;
