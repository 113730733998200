

import * as React from "react";
import { IEnvironment } from "./Environments";
import { ICustomersApiService } from "./services/api/CustomersApiService";
import { IBsApimService } from "./services/api/BsApimService";
import { ILogger } from "./utils/Logger";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { IAppSettings } from './services/models/AppSettings';
export interface IAppContext {
    isInDebugMode: boolean;
    environment: IEnvironment;
    correlationId: string;
    customersApiService?: ICustomersApiService;
    appSettings?: IAppSettings;
    bsApimService?: IBsApimService;
    appInsights: ApplicationInsights;
    logger: ILogger;
    token?: string;

}

export const AppContext: React.Context<IAppContext> = React.createContext({ isInDebugMode: false } as IAppContext);