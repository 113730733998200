import styled, { css } from "styled-components";
//https://stackoverflow.com/questions/52226596/styled-components-defaultprops
import { Button, ButtonHTMLProps, ButtonProps } from '@rmwc/button';
import '@rmwc/button/styles';


export interface IButtonProps {
    variant?: 'primary' | 'secondary';
    children?: React.ReactNode;

}

const variantStyles = {
    primary: css`
       && { color: white ;
        background-color: var(--color-action-blue) ;
    }
    `,
    secondary: css`
       && { background: #FFFFFF;
        /* Action Blue */

        border: 2px solid var(--color-action-blue);
        box-sizing: border-box;
        border-radius: 4px;
    }
    `,
};





export const StyledButton = styled(Button) <IButtonProps & ButtonHTMLProps & ButtonProps>`
    
    background:white;
    display: block;
     font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-transform: none;
    height:48px;

    ${props => variantStyles[props.variant || 'primary']};

    &:hover {        font-weight: 500;    };
    &:disabled {        color:darkgray ;   };
`;

export const LinkButton = styled.button`
     color: blue;
        font-family: inherit;
        font-size: inherit;
        background-color: transparent;
        border: none;
        cursor: pointer;
        text-decoration: underline;
        display: inline;
        margin: 0;
        padding: 0;
`;

