import React from "react"
import { IButtonProps, StyledButton } from "../Button"
import styles from './ButtonGroup.module.scss';

export interface IButtonGroupProps {
    buttonConfigs: IButtonConfig[],
    className?: string;
}
export interface IButtonConfig {
    text: string;
    props?: IButtonProps;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    type?: string;
    disabled?: boolean;
}
export const ButtonGroup = ({ buttonConfigs, className }: IButtonGroupProps) => {


    return <div className={`${styles.buttonGroupContainer}  ${className}`}>
        {buttonConfigs.map((config, index) => {
            const buttonVariant = config.props ? config.props.variant : 'primary';
            return <StyledButton
                type={config.type || 'button'}
                className={`mr-2 mb-2 btn btn-${buttonVariant} ${styles.btnW100Mobile}`}
                key={`choiceBtn-${index}`}
                variant={buttonVariant}
                onClick={config.onClick}
                disabled={config.disabled}>
                {config.text}
            </StyledButton>
        })}
    </div >

}
