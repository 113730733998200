// https://www.c-sharpcorner.com/article/learn-about-extension-methods-in-typescript/

declare global {
    interface String {
        capitalize(): string;
    }
}

// eslint-disable-next-line no-extend-native
String.prototype.capitalize = function (): string {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

export { };