export interface IEnvironment {
    URL_NAME: string;
    CUSTOMERS_API_URL: string;
    BS_APIM_URL: string,
    BS_APIM_CUSTOMERS_RELATIVE_URL: string,
    BS_APIM_SUBSCRIPTION_KEY: string;
    APP_INSIGHTS_INSTRUMENTATION_KEY: string;
    DEFAULT_REDIR_URL: string;
    DEFAULT_REDIR_TEXT: string;

}

export interface IEnvironments {
    [key: string]: IEnvironment;
}


export const Environments: IEnvironments = {
    dev: {
        URL_NAME: 'dev',
        CUSTOMERS_API_URL: 'https://dhi-customers-webapi-dev.azurewebsites.net',
        BS_APIM_URL: 'https://dhi-bsapi-dev.azure-api.net',
        BS_APIM_CUSTOMERS_RELATIVE_URL: '/customers',
        BS_APIM_SUBSCRIPTION_KEY: '5e0db77696204fed8c537e8fd0749445',
        APP_INSIGHTS_INSTRUMENTATION_KEY: '7d78b0d0-8b32-4609-8b41-26ea95f3faa6',
        DEFAULT_REDIR_URL: 'https://dhigroupsupportdev.powerappsportals.com/SignIn?ReturnUrl=/',
        DEFAULT_REDIR_TEXT: 'Continue to DHI support'
    },
    test: {
        URL_NAME: 'test',
        CUSTOMERS_API_URL: 'https://dhi-customers-webapi-test.azurewebsites.net',
        BS_APIM_URL: 'https://dhi-bsapi-test.azure-api.net',
        BS_APIM_CUSTOMERS_RELATIVE_URL: '/customers',
        BS_APIM_SUBSCRIPTION_KEY: 'a448c8c77ec84cfdb11525acada1b515',
        APP_INSIGHTS_INSTRUMENTATION_KEY: 'd269a787-5524-4372-a13a-e6079019d2e8',
        DEFAULT_REDIR_URL: 'https://dhigroupsupporttest.microsoftcrmportals.com/SignIn?ReturnUrl=/',
        DEFAULT_REDIR_TEXT: 'Continue to DHI support'
    },
    live: {
        URL_NAME: 'live',
        CUSTOMERS_API_URL: 'https://dhi-customersportal-webapi.azurewebsites.net',
        BS_APIM_URL: 'https://dhi-bsapi.azure-api.net',
        BS_APIM_CUSTOMERS_RELATIVE_URL: '/customers',
        BS_APIM_SUBSCRIPTION_KEY: 'd3c7b7071b3343d893ecb8c39eb3b8c1',
        APP_INSIGHTS_INSTRUMENTATION_KEY: '37c61b17-9491-4668-b9de-36413774bbfd',
        DEFAULT_REDIR_URL: 'https://support.dhigroup.com/SignIn?ReturnUrl=/',
        DEFAULT_REDIR_TEXT: 'Continue to DHI support'
    },
    local: {
        URL_NAME: 'local',
        CUSTOMERS_API_URL: 'https://dhi-customers-webapi-dev.azurewebsites.net',//'https://localhost:5001',
        BS_APIM_URL: 'https://dhi-bsapi-test.azure-api.net',
        BS_APIM_CUSTOMERS_RELATIVE_URL: '/customers',
        BS_APIM_SUBSCRIPTION_KEY: 'a448c8c77ec84cfdb11525acada1b515',
        APP_INSIGHTS_INSTRUMENTATION_KEY: '8cf987df-31e6-49d6-8ee1-23dd6d119442',
        DEFAULT_REDIR_URL: 'https://dhigroupsupporttest.microsoftcrmportals.com/SignIn?ReturnUrl=/',
        DEFAULT_REDIR_TEXT: 'Continue to DHI support'
    },
    localwithapi: {
        URL_NAME: 'localwithapi',
        CUSTOMERS_API_URL: 'https://localhost:5001',
        BS_APIM_URL: 'https://dhi-bsapi-test.azure-api.net',
        BS_APIM_CUSTOMERS_RELATIVE_URL: '/customers',
        BS_APIM_SUBSCRIPTION_KEY: 'a448c8c77ec84cfdb11525acada1b515',
        APP_INSIGHTS_INSTRUMENTATION_KEY: '8cf987df-31e6-49d6-8ee1-23dd6d119442',
        DEFAULT_REDIR_URL: 'https://dhigroupsupporttest.microsoftcrmportals.com/SignIn?ReturnUrl=/',
        DEFAULT_REDIR_TEXT: 'Continue to DHI support'
    },

} as const;
