/* import { useState } from "react";

export const useConstructor = (callBack = () => { }) => {
    const [hasBeenCalled, setHasBeenCalled] = useState(false);
    if (hasBeenCalled) return;
    callBack();
    setHasBeenCalled(true);
} */

// https://github.com/clayrisser/use-constructor/blob/master/src/index.ts
import { useRef } from 'react';

export type Constructor = (...args: any[]) => any;

export const useConstructor = (constructor: Constructor) => {
    const isConstructorCalled = useRef(false);
    if (!isConstructorCalled.current) {
        constructor();
        isConstructorCalled.current = true;
    }
}