/*
 * @Author: Filip Mateásko, ©DHI  
 * @Date: 2021-11-09 18:07:25 
 * @Last Modified by: Filip Mateásko
 * @Last Modified time: 2021-11-09 18:11:28
 * The select component to for overall usage. It was changed from RMWC to MUI, because there were 
 * problems with autocomplete in RMWC one (when using "enhanced" mode, autocomplete doesn't work there)
 */


import React from 'react';

import MuiSelect from '@mui/material/Select';
import styles from './Select.module.scss';
import { FormikProps } from 'formik';
import { FormControl, InputLabel, MenuItem } from '@mui/material';
export interface ISelectProps<T> {
  name: string;
  placeholder?: string;
  label?: string;
  data?: T[];
  idSelectFc: (item: T) => string;
  nameSelectFc: (item: T) => string;
  selectMsg?: string;
  required?: boolean;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  className?: string;
  invalid?: boolean;
  formikProps: FormikProps<any>;
  disabled?: boolean;
}

export const Select = <T extends object>({ name, label, data, idSelectFc, nameSelectFc, selectMsg, required, className, invalid, onChange, onBlur, formikProps, disabled }: ISelectProps<T>) => {

  const title = `${label || name}`;
  const { touched, errors } = formikProps;


  return <FormControl
    variant="filled"
    sx={{
      minWidth: 120,
      '& label.Mui-focused': {
        color: '#00A3EA',
      },
    }}
    required={required}
    error={invalid || required ? (touched[name] && Boolean(errors[name])) : false}
    className={`${className || ''} ${styles.select} m-0`}>
    <InputLabel>{title}</InputLabel>
    <MuiSelect
      name={name}
      placeholder={selectMsg}
      defaultValue=""
      onChange={(e: any) => onChange ? onChange(e) : formikProps.setFieldValue(name, e.target.value)}
      onBlur={(e: any) => onBlur ? onBlur(e) : formikProps.setFieldTouched(name)}
      required={required}
    >

      {data && data.map((item: T, index: number) => {
        return <MenuItem key={`mnuItem${name}${index}`} value={idSelectFc(item)}>{nameSelectFc(item)}</MenuItem>
      })}
    </MuiSelect>
  </FormControl>
};




