import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { IEnvironment } from 'Environments';

let reactPlugin: ReactPlugin;
let appInsights: ApplicationInsights;

export const createTelemetryService = () => {

    const initialize = (environment: IEnvironment) => {
        const browserHistory = createBrowserHistory({ basename: '' });
        if (!environment) {
            throw new Error('environment not provided in ./src/telemetry-provider.jsx')
        }

        reactPlugin = new ReactPlugin();

        appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: environment.APP_INSIGHTS_INSTRUMENTATION_KEY,
                maxBatchInterval: 0,
                disableFetchTracking: false,
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: {
                        history: browserHistory
                    }
                }
            }
        });

        appInsights.loadAppInsights();
    }


    return { reactPlugin, appInsights, initialize };
};

export const ai = createTelemetryService();
export const getAppInsights = () => appInsights;


