import { Select } from 'components/Forms/Select/Select';
import { Form, Formik, FormikProps } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import styles from './AccountCreation.module.scss';
import * as Yup from 'yup';
import { AccountRequest } from 'services/models/Account';
import { Country } from 'services/models/Country';
import Segment from 'services/models/Segment';
import CompanySize from 'services/models/CompanySize';
import { State } from 'services/models/State';
import { Grid, GridRow, GridCell } from '@rmwc/grid';
import { ButtonGroup } from 'components/Forms/ButtonGroup/ButtonGroup';
import { CircularProgress } from '@rmwc/circular-progress';
import '@rmwc/textfield/styles';
import { TextField } from 'components/Forms/TextField';
import { InputValidation, patterns } from 'utils/InputValidation';

export interface IAccountCreationProps {
  contactEmail: string;
  onAccountCreated: (accountId: string) => void;
  onCanceled: () => void;
}

enum ProcessState {
  initialized,
  optionListsLoaded,
  requestSent,
  error
}

const newAccountFormInitialValues: AccountRequest = {
  name: '',
  dhiCompanyId: '',
  vatNumber: '',
  address1: '',
  address2: '',
  address3: '',
  city: '',
  postalCode: '',
  countryId: '',
  stateId: '',
  companySizeId: '',
  companySegmentId: '',
  phone: '',
  email: ''
}


const alphanumErrorText = 'Only letters, numbers, spaces, period, comma, dash and slash are allowed';
const companyNameErrorText = alphanumErrorText + "+ & '";

const getAccountCreationSchema = (stateRequired: boolean) =>
  Yup.object().shape({
    name: Yup.string()
      .min(2, "Company name must have at least 2 characters")
      .max(200, "Company name can have 200 characters max")
      .required("Required")
      .test(
        "company name validity",
        companyNameErrorText,
        (value: string | undefined) => {
          if (typeof value === "string") {            
            return InputValidation.isCompanyNameValid(value);
          }
          return false;
        }
      ),
    dhiCompanyId: Yup.string()
      .min(1, "Please, write the company ID")
      .matches(patterns.alphaNumeric, alphanumErrorText)
      .required("Required"),
    vatNumber: Yup.string()
      .min(2, "VAT number must be longer")
      .matches(patterns.alphaNumeric, alphanumErrorText),
    address1: Yup.string()
      .min(2, "Please, write the company address")
      .matches(patterns.alphaNumeric, alphanumErrorText)
      .required("Company address is required"),
    city: Yup.string()
      .min(2, "City should have at least 2 characters")
      .matches(patterns.alphaNumeric, alphanumErrorText)
      .required("City is required"),
    countryId: Yup.string()
      .min(0, "Please, select a country")
      .required("Country is required"),
    stateId: Yup.string().when(["countryId"], {
      is: (countryId: string) => {
        return countryId !== null && stateRequired;
      },
      then: Yup.string().required("State is required"),
    }),
    companySizeId: Yup.string()
      .min(0, "Please, select the company size")
      .required("Company size is required"),
    companySegmentId: Yup.string()
      .min(0, "Please, select the company type")
      .required("Company type is required"),
  });



const AccountCreation = ({ onAccountCreated, onCanceled, contactEmail }: IAccountCreationProps) => {
  const context = useContext(AppContext);
  const [processState, setProcessState] = useState<ProcessState>(ProcessState.initialized);
  const [countries, setCountries] = useState<Country[]>();
  const [states, setStates] = useState<State[]>();
  const [segments, setSegments] = useState<Segment[]>();
  const [companySizes, setCompanySizes] = useState<CompanySize[]>();
  const [error, setError] = useState();
  const [stateRequired, setStateRequired] = useState<boolean>(false);
  // newAccountFormInitialValues.email = contactEmail;

  /// run just once at the component loading
  useEffect(() => {

    //if (optionListLoaded) return;
    const bsApimSvc = context.bsApimService!;
    bsApimSvc!.GetCountries().then((sResponse) => { setCountries(sResponse.response); });
    bsApimSvc!.GetCompanySizes().then((sResponse) => {
      setCompanySizes(
        sResponse.response);
    });
    bsApimSvc!.GetSegments().then((sResponse) => {
      setSegments(
        sResponse.response);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) //<-- run just once

  return <div className={styles.AccountCreation}>
    {/*     {// on component initialization
      processState !== ProcessState.error && <> */}
    <h3>New company registration</h3>
    <Formik
      initialValues={newAccountFormInitialValues}
      onSubmit={(values, actions) => {
        const CreateAccount = async (values: AccountRequest) => {
          const { customersApiService: customersAPI } = context;
          try {
            const accountId = (await customersAPI!.CreateAccount(values)).response;
            //raise the event to notify parent
            onAccountCreated(accountId);
          } catch (err: any) {
            setError(err.message);
            setProcessState(ProcessState.error);
          }

        }
        CreateAccount(values);
        setProcessState(ProcessState.requestSent);
        actions.setSubmitting(false);
      }}
      validationSchema={getAccountCreationSchema(stateRequired)}
    >
      {(formikProps: FormikProps<any>) => {
        const { isSubmitting, setFieldValue, values } = formikProps;
        return (
          <Form>
            <Grid>
              <GridRow>
                <GridCell span={12} className={styles.hCenter}>
                    <TextField name="name" label="Company name" value={values.name} formikProps={formikProps} required />
                </GridCell>               

              </GridRow>
              <GridRow>
                
                <GridCell span={4} phone={4} className={styles.hCenter}>
                  <TextField
                    name="phone"
                    label="Phone"                    
                    formikProps={formikProps}
                  />
                </GridCell>
                <GridCell span={4} className={styles.hCenter}>
                    <TextField name="vatNumber" label="VAT number" value={values.vatNumber} formikProps={formikProps} />
                </GridCell>
                <GridCell span={4} className={styles.hCenter}>
                    <TextField name="dhiCompanyId" label="Company reg. no." value={values.dhiCompanyId} formikProps={formikProps} required />
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={4} className={styles.hCenter}>
                    <TextField name="address1" label="Address line #1" value={values.address1} formikProps={formikProps} required />
                </GridCell>
                <GridCell span={4} className={styles.hCenter}>
                    <TextField  name="address2" label="Address line #2" value={values.address2} formikProps={formikProps} />
                </GridCell>
                <GridCell span={4} className={styles.hCenter}>
                    <TextField name="address3" label="Address line #3" value={values.address3} formikProps={formikProps} />
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={4} className={styles.hCenter}>
                    <TextField name="city" label="City" value={values.city} formikProps={formikProps} required />
                </GridCell>
                <GridCell span={4} className={styles.hCenter}>
                    <TextField name="postalCode" label="Postal code" value={values.postalCode} formikProps={formikProps} required />
                </GridCell>
                <GridCell span={4} className={styles.hCenter}><Select<Country> name="countryId"
                  label="Country"
                  data={countries}
                  idSelectFc={(i: Country) => i.id.toString()}
                  nameSelectFc={(i: Country) => i.name}
                  onChange={(e) => {
                    const selectedCountryId = e.target.value;
                    const country = countries?.find(c => c.id.toString() === selectedCountryId);
                    setFieldValue("countryId", selectedCountryId);
                    const stateRequired = country !== null && country!.states !== null && country!.states!.length > 0;
                    if (stateRequired) setStates(country!.states);
                    setStateRequired(stateRequired);
                  }}
                  formikProps={formikProps}
                  required /></GridCell>
              </GridRow>
              {stateRequired && <GridRow>
                <GridCell span={8} className={styles.hCenter}></GridCell>
                <GridCell span={4} className={styles.hCenter}>
                  <Select<State> name="stateId"
                    label="State"
                    data={states}
                    idSelectFc={(i: State) => i.id.toString()}
                    nameSelectFc={(i: State) => i.name}
                    formikProps={formikProps}
                    required />
                </GridCell>
              </GridRow>
              }

              <GridRow>
                <GridCell span={4} className={styles.hCenter}>
                  <Select<CompanySize> name="companySizeId"
                    label="Company size"
                    data={companySizes}
                    idSelectFc={(i: CompanySize) => i.id.toString()}
                    nameSelectFc={(i: CompanySize) => i.name}
                    formikProps={formikProps}
                    required />
                </GridCell>

                <GridCell span={8} className={styles.hCenter}>
                  <Select<Segment> name="companySegmentId"
                    label="Company type"
                    data={segments}
                    idSelectFc={(i: Segment) => i.id.toString()}
                    nameSelectFc={(i: Segment) => i.name}
                    formikProps={formikProps}
                    required />
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={12} className={styles.hCenter}>
                  <ButtonGroup buttonConfigs={[
                    { text: "Cancel", props: { variant: 'secondary' }, onClick: () => { onCanceled(); }, disabled: isSubmitting },
                    { text: "Register company & associate me", props: { variant: 'primary' }, type: 'submit', onClick: () => { return true; }, disabled: isSubmitting || processState === ProcessState.requestSent },
                  ]} />
                </GridCell>
              </GridRow>
            </Grid>
          </Form>
        )
      }}
    </Formik>
    {/* </>
    } */}
    {// when new account creation request has been sent
      processState === ProcessState.error && <>
        Error occured: {error}...
      </>
    }
    {// when new account creation request has been sent
      processState === ProcessState.requestSent && <>
        <CircularProgress />  We are processing your request, please wait...
      </>
    }
  </div >
};

export default AccountCreation;
