
import { IEnvironment } from "../../Environments";
import { ServiceResponse } from "../api-common/ApiResponse";

import { Country } from "../models/Country";
import { ApimClient } from "../api-common/ApimClient";
import CompanySize from '../models/CompanySize';
import Segment from '../models/Segment';
import { ILogger } from "utils/Logger";

export interface IBsApimService {
    token?: string;
    GetCountries(): Promise<ServiceResponse<Country[]>>;
    GetCompanySizes(): Promise<ServiceResponse<CompanySize[]>>;
    GetSegments(): Promise<ServiceResponse<Segment[]>>;
}
export class BsApimService implements IBsApimService {
    private apiClient: ApimClient;
    /**
     * Constructor
     */
    constructor(env: IEnvironment, logger: ILogger, correlationId: string) {
        this.apiClient = new ApimClient(env.BS_APIM_URL, env.BS_APIM_SUBSCRIPTION_KEY, logger, correlationId);
    }

    GetCountries = async (): Promise<ServiceResponse<Country[]>> => {
        return await this.apiClient.GetOf<Country[]>(
            `countries`,
            "countries",
            r => {
                let countries = r.value ? (r.value as Country[]) : (r as Country[]);
                // United Kingdom is included twice, once with isoCode GB and once with UK
                // the UK code is not valid, moreover the country name for this case is
                // 'see GB' and we don't want to see this in UI
                return countries.filter(c => c.isoCode !== "UK")
                                .sort((a, b) => a.name < b.name ? -1 : 1);
            });
    }

    GetCompanySizes = async (): Promise<ServiceResponse<CompanySize[]>> => {
        return await this.apiClient.GetOf<CompanySize[]>(
            `companysizes`,
            "companysizes",
            r => r.value ? (r.value as CompanySize[]) : (r as CompanySize[]));
    }

    GetSegments = async (): Promise<ServiceResponse<Segment[]>> => {
        return await this.apiClient.GetOf<Segment[]>(
            `segments`,
            "segments",
            r => r.value ? (r.value as Segment[]) : (r as Segment[]));
    }
}