

import { ILogger } from 'utils/Logger';
import { ApiError } from './ApiError';
import { ApiResponse, ServiceResponse } from './ApiResponse';


export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

/**
 * The base api service class. It defines basic web api methods, and virtual methods to add headers, authentication and to select the fetch method
 */
export abstract class BaseApiClient {

    protected BASE_API_URL: string;
    protected BASE_RELATIVE_URL?: string;
    protected logger: ILogger;
    private correlationId: string;

    /**
     * @param baseApiUrl        The base absolute url of the API
     * @param logger            The logger
     * @param correlationId     The correlationID added to each request (typically is one correlation ID per session = multiple requests)
     * @param baseRelativeUrl   The base relative url for all requests - the url is relative to baseApiUrl     
     */
    constructor(baseApiUrl: string, logger: ILogger, correlationId: string, baseRelativeUrl?: string) {

        this.BASE_API_URL = baseApiUrl;
        if (baseRelativeUrl) {
            this.BASE_RELATIVE_URL = baseRelativeUrl.startsWith('/') ? baseRelativeUrl : `/${baseRelativeUrl}`;
        }
        this.logger = logger;
        this.correlationId = correlationId;
    }

    /**
     * Virtual method to add headers to the request in async form
     * @param options  
     * @returns 
     */
    public AddAdditionalRequestHeadersAsync(options: RequestInit): Promise<void> | undefined {
        return undefined;
    };

    /**
   * Virtual method to add authentication to the request in async form
   * @param options
   * @returns
   */
    public AddAuthenticationAsync(options: RequestInit): Promise<void> | undefined {
        return undefined;
    };

    /**
   * Virtual method to add headers to the request
   * @param options
   * @returns
   */
    public AddAdditionalRequestHeaders(options: RequestInit): void | undefined {
        return undefined;
    };


    /**
     * Virtual method to add authentication to the request
     * @param options
     * @returns
     */
    public AddAuthentication(options: RequestInit): void | undefined {
        return undefined;
    };

    /**
     * Gets the fetch method to be used to fetch data - can be overwritten in sub classes. By default uses "fetch"
     * @param authenticationRequired  
     * @param token 
     * @returns 
     */
    public GetFetchMethod(authenticationRequired: boolean): (url: string, options?: any) => Promise<any> {
        return fetch;
    }


    /**
    * General web api GET
    * @param url The url to be called (without preceding base api url and initial slash)
    * @param body The request body
    * @param authenticationRequired If false, the authentication header will not be added to the request
    * @param statusCodeHandlers Optional http result status code handlers - user can define methods to handle specific status codes
    * @returns Promise<ApiResponse>
    */
    public async Get(
        url: string,
        authenticationRequired: boolean = true,
        statusCodeHandlers: { [code: number]: (response: Response) => void } = {}):
        Promise<ApiResponse> {
        return await this._execute('GET', url, null, authenticationRequired, statusCodeHandlers);
    }

    /**
     * Generic web api GET
     * @param url The url to be called (without preceding base api url and initial slash)
     * @param entityNameToLog The name of the fetched entity - this is written to the log
     * @param authenticationRequired If false, the authentication header will not be added to the request
     * @param statusCodeHandlers Optional http result status code handlers - user can define methods to handle specific status codes
     * @returns ServiceResponse<T>
     */
    public async GetOf<T>(
        url: string,
        entityNameToLog: string,
        transformationFunction: (S: any) => T,
        authenticationRequired: boolean = true,
        statusCodeHandlers: { [code: number]: (response: Response) => void } = {})
        : Promise<ServiceResponse<T>> {
        try {
            this.logger.logInfo(`Fetching ${entityNameToLog}...`);
            const apiRes: ApiResponse = await this.Get(url, authenticationRequired, statusCodeHandlers);
            this.logger.logInfo(`'${entityNameToLog}' info fetched.`);
            this.logger.logDebug(`   result: ${JSON.stringify(apiRes.response)}`);
            return {
                response: transformationFunction(apiRes.response),
                logId: apiRes.logId
            };
        } catch (e) {
            console.error(`Unable to fetch ${entityNameToLog}, ${JSON.stringify(e)}`);
            throw e;
        }
    }


    /**
    * General web api POST
    * @param url The url to be called (without preceding base api url and initial slash)
    * @param body The request body
    * @param authenticationRequired If false, the authentication header will not be added to the request
    * @param statusCodeHandlers Optional http result status code handlers - user can define methods to handle specific status codes
    * @returns Promise<ApiResponse>
    */
    public async Post(
        url: string,
        body: any,
        authenticationRequired: boolean = true,
        statusCodeHandlers: { [code: number]: (response: Response) => void } = {})
        : Promise<ApiResponse> {
        return await this._execute('POST', url, body, authenticationRequired, statusCodeHandlers);
    }

    /**
     * Generic web api POST
     * @param url The url to be called (without preceding base api url and initial slash)
     * @param entityNameToLog The name of the posted entity - this is written to the log
     * @param authenticationRequired If false, the authentication header will not be added to the request
     * @param statusCodeHandlers Optional http result status code handlers - user can define methods to handle specific status codes
     * @returns ServiceResponse<T>
     */
    public async PostOf<T>(
        url: string,
        body: any,
        successLogMsg: string,
        failLogMsg: string,
        transformationFunction?: (S: any) => T,
        authenticationRequired: boolean = true,
        statusCodeHandlers: { [code: number]: (response: Response) => void } = {})
        : Promise<ServiceResponse<T>> {
        try {
            const apiRes: ApiResponse = await this.Post(url, body, authenticationRequired, statusCodeHandlers);
            const response = apiRes.response;
            this.logger.logInfo(successLogMsg);
            this.logger.logDebug(`   result: ${JSON.stringify(response)}`);
            return {
                response: transformationFunction ? transformationFunction(response) : response,
                logId: apiRes.logId
            };
        } catch (e) {
            console.error(`${failLogMsg}: ${JSON.stringify(e)}`);
            throw e;
        }
    }

    /**
     * General web api DELETE
     * @param url The url to be called (without preceding base api url and initial slash)
     * @param entityNameToLog The name of the patched entity - this is written to the log
     * @param authenticationRequired If false, the authentication header will not be added to the request
     * @param statusCodeHandlers Optional http result status code handlers - user can define methods to handle specific status codes
     * @returns Promise<ApiResponse>
     */
    public async Delete(
        url: string,
        authenticationRequired: boolean = true,
        statusCodeHandlers: { [code: number]: (response: Response) => void } = {})
        : Promise<ApiResponse> {
        return await this._execute('DELETE', url, null, authenticationRequired, statusCodeHandlers);
    }

    /**
     * Generic web api DELETE
     * @param url The url to be called (without preceding base api url and initial slash)
     * @param entityNameToLog The name of the deleted entity - this is written to the log
     * @param authenticationRequired If false, the authentication header will not be added to the request
     * @param statusCodeHandlers Optional http result status code handlers - user can define methods to handle specific status codes
     * @returns ServiceResponse<T>
     */
    public async DeleteOf<T>(
        url: string,
        entityNameToLog: string,
        authenticationRequired: boolean = true,
        statusCodeHandlers: { [code: number]: (response: Response) => void } = {})
        : Promise<ServiceResponse<T>> {
        try {
            this.logger.logInfo(`Deleting ${entityNameToLog}`);
            const apiRes = await this.Delete(url, authenticationRequired, statusCodeHandlers)
            const response = apiRes.response;
            this.logger.logInfo(`'${entityNameToLog}' deleted.`);
            return {
                response: { ...response } as T,
                logId: apiRes.logId
            };
        } catch (e) {
            console.error(`Unable to delete ${entityNameToLog}, error: ${JSON.stringify(e)}`);
            throw e;
        }
    }

    /**
    * General web api PATCH
    * @param url The url to be called (without preceding base api url and initial slash)
    * @param body The request body
    * @param authenticationRequired If false, the authentication header will not be added to the request
    * @param statusCodeHandlers Optional http result status code handlers - user can define methods to handle specific status codes
    * @returns Promise<ApiResponse>
    */
    public async Patch(
        url: string,
        body: any,
        authenticationRequired: boolean = true,
        statusCodeHandlers: { [code: number]: (response: Response) => void } = {})
        : Promise<ApiResponse> {
        return await this._execute('PATCH', url, body, authenticationRequired, statusCodeHandlers);
    }

    /**
    * Generic web api PATCH
    * @param url The url to be called (without preceding base api url and initial slash)
    * @param body The request body
    * @param entityNameToLog The name of the patched entity - this is written to the log
    * @param authenticationRequired If false, the authentication header will not be added to the request
    * @param statusCodeHandlers Optional http result status code handlers - user can define methods to handle specific status codes
    * @returns ServiceResponse<T>
    */
    public async PatchOf<T>(
        url: string,
        body: any,
        entityNameToLog: string,
        authenticationRequired: boolean = true,
        statusCodeHandlers: { [code: number]: (response: Response) => void } = {})

        : Promise<ServiceResponse<T>> {

        try {
            this.logger.logInfo(`Updating ${entityNameToLog}`);
            const apiRes = await this.Patch(url, body, authenticationRequired, statusCodeHandlers);
            const response = apiRes.response;
            this.logger.logInfo(`'${entityNameToLog}' updated.`);
            return {
                response: { ...response } as T,
                logId: apiRes.logId
            };
        } catch (e) {
            console.error(`Unable to update ${entityNameToLog}, error: ${JSON.stringify(e)}`);
            throw e;
        }
    }


    /**
     * General web api call - this is the basic method called from all above methods
     * @param method Http method (GET, POST, PUT, PATCH, DELETE)
     * @param url The url to be called (without preceding base api url and initial slash)
     * @param body The request body
     * @param authenticationRequired If false, the authentication header will not be added to the request
     * @param statusCodeHandlers Optional http result status code handlers - user can define methods to handle specific status codes
     * @returns Promise<ApiResponse>
     */
    private async _execute(
        method: HttpMethod,
        url: string,
        body?: any,
        authenticationRequired: boolean = true,
        statusCodeHandlers: { [code: number]: (response: Response) => void } = {}

    ): Promise<ApiResponse> {


        url = `${this.BASE_API_URL}${this.BASE_RELATIVE_URL || ''}/${url}`;
        const defaultHeaders = new Headers();
        defaultHeaders.set('Content-Type', 'application/json');
        defaultHeaders.set('Accept', 'application/json');
        defaultHeaders.set('x-correlation-id', this.correlationId);
        var options: RequestInit =
        {
            method: method,
            mode: 'cors',
            headers: defaultHeaders
        }

        if (body) {
            options.body = JSON.stringify(body)
        }
        //add authentication
        if (this.AddAuthenticationAsync)
            await this.AddAuthenticationAsync(options);
        if (this.AddAuthentication)
            this.AddAuthentication(options);

        // add additional headers, if any
        if (this.AddAdditionalRequestHeadersAsync)
            await this.AddAdditionalRequestHeadersAsync(options);
        if (this.AddAdditionalRequestHeaders)
            this.AddAdditionalRequestHeaders(options);

        this.logger.logInfo(`Calling url '${url}' with body '${body ? JSON.stringify(body) : ''}'`);


        const fetchMethod = this.GetFetchMethod(authenticationRequired);
        const response: Response = await fetchMethod(url, options);
        // write the log id to the console
        // const logId = response.headers.get('logId') || "";
        //this.logger.logDebug(`     action app log id = ${logId}`);
        var status = response.status;

        //we have to check, if there is any existing user defined handler for given status code
        if (statusCodeHandlers[status]) {
            //if so, we call it
            statusCodeHandlers[status](response);
        } else {
            //no custom status code handlers available 
            if (!response.ok) {
                //response didn't return success

                var responseText = await response.text();
                //var responseJson = await response.json();

                var msg = `${method} failed: Status: ${status}, Response: ${responseText}`;
                const err = new ApiError(msg, responseText, this.correlationId);//logId);
                this.logger.logError(err);
                throw err;


            }
        }

        try {
            const resp = (response.status === 204 || response.status === 202) ? null : await response.json();

            return {
                logId: this.correlationId,
                response: resp
            };
        } catch (error: any) {
            this.logger.logError(error);
            const msg = error.message;
            console.error(msg);
            console.error(`Error in ApiClient calling '${url}', method '${method}': ${JSON.stringify(error)}`)
            throw error;
        }
    }

    /**
     * Utility function to add headers to the request
     * @param options 
     * @param headers 
     */
    public AddRequestHeaders(options: RequestInit, headers: { key: string, value: string }[]) {
        if (headers && headers.length > 0) {
            var currentHeaders = options.headers as Headers;
            /*             const newHeaders: HeadersInit = new Headers();
                        currentHeaders.forEach(h => {
                            newHeaders.set(h., h.value);
                        }); */
            //const newHeaders = new Headers();

            headers.forEach(h => {
                currentHeaders.set(h.key, h.value);
            });

            //const mergedHeaders = { ...currentHeaders, ...newHeaders };

            options.headers = currentHeaders;
        }
    }
}
