import DialogContainer from 'components/DialogContainer/DialogContainer';
import { StyledButton } from 'components/Forms/Button';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './NotRegisteredInfo.module.scss';

export interface INotRegisteredInfoProps {
  afterRegistrationRedirectUrl: string;
  afterRegistrationRedirectLinkText: string;
}
const NotRegisteredInfo = ({ afterRegistrationRedirectUrl, afterRegistrationRedirectLinkText }: INotRegisteredInfoProps) => {

  const history = useHistory();
  return <DialogContainer title="Registration required" showTitle={false} showProgressBar={false} showCross={false}>

    <div className={`${styles.font14} ${styles.brandBlue} mt-4 mb-4`}>

      <div>
        <div className="mb-6">You are not registered yet, please register by clicking the button bellow. Thank you.</div>
        <div className="hCenter">
          <StyledButton
            type="button"
            className={styles.formButton}
            onClick={() => { history.push('/registration', { redir: afterRegistrationRedirectUrl, redirtxt: afterRegistrationRedirectLinkText }); }}
            variant="secondary">REGISTER</StyledButton>
        </div>
      </div >
      {/*       <div className={`mt-4 ${styles.bold}`}>
        Thank you!
      </div> */}
    </div>

  </DialogContainer >
};

export default NotRegisteredInfo;
