
import React, { useContext, useState } from 'react';
import { AppContext } from '../../AppContext';
import styles from './ClientRegistration.module.scss';

import { ContactInfo } from '../../services/models/ContactInfo';

import UserRegistration from './UserRegistration';
import EmailVerification from 'components/EmailVerification/EmailVerification';
import AccountAssociation from 'components/AccountAssociation/AccountAssociation';

import DialogContainer from 'components/DialogContainer/DialogContainer';
import { LinkButton } from '../Forms/Button';
import Progress from 'components/Progress/Progress';
import { ContactUpdate } from 'services/models/ContactUpdate';

import { useHistory } from "react-router-dom"
import { Contact } from 'services/models/Contact';
import { ContactRequest } from 'services/models/ContactRequest';



export interface IClientRegistrationProps {
    waitForVerificationResult?: boolean;
    afterRegistrationRedirectUrl?: string;
    afterRegistrationRedirectLinkText?: string;
    cancelRedirectUrl?: string;
    onCancelAction?: string;
    email?: string;
    contactCode?: string;
}

enum ProcessState {
    userEmailNotChecked,
    waitingForUserInfo,
    initialPage,
    verificationEmailSent,
    waitingForEmailVerificationAndUserCreation,
    userInDynamicsCreated,
    userInDynamicsWaitingForEmailVerification,
    mikeCloudRegistrationInvitationSent,
    userRegistered,
    existingMikeCloudUserPaired,//user with the same email was found already existing in the MikeCloudAD and it was paired with newly created Dynamics account
    error
}

const ClientRegistration = ({ email: emailProp, waitForVerificationResult, afterRegistrationRedirectUrl,
    afterRegistrationRedirectLinkText, onCancelAction, cancelRedirectUrl, contactCode }: IClientRegistrationProps) => {
    const context = useContext(AppContext);
    const { logger } = context;
    const [processState, setProcessState] = useState<ProcessState>(waitForVerificationResult ? ProcessState.waitingForEmailVerificationAndUserCreation : ProcessState.initialPage);//
    const [error, setError] = useState<Error>();
    const [email, setEmail] = useState<string>(emailProp || '');
    const [contactInfo, setContactInfo] = useState<ContactInfo | null>();
    const [accountAssociated, setAccountAssociated] = useState<boolean>(false);
    const [sendingEmail, setSendingEmail] = useState<boolean>(false);
    const [processing, setProcessing] = useState<boolean>(false);
    const history = useHistory();
    const [newContact, setNewContact] = useState<Contact>();
    const [contactRequest, setContactRequest] = useState<ContactRequest>();

    logger.logInfo(`ClientRegistration component is in state '${ProcessState[processState]}'`);
    const GetSelectedStepNr = () => {
        switch (processState) {
            case ProcessState.initialPage:
                return 1;
            case ProcessState.waitingForEmailVerificationAndUserCreation:
            case ProcessState.verificationEmailSent:
            case ProcessState.userInDynamicsWaitingForEmailVerification:
                return 2;
            case ProcessState.userInDynamicsCreated:
                return 3;
            case ProcessState.mikeCloudRegistrationInvitationSent:
                return 4;
            default: return 1;
        }
    }

    const GetTitle = () => {
        //console.log(`GetTitle called:processState: ${processState}`);
        switch (processState) {
            case ProcessState.initialPage: return 'Register';
            case ProcessState.verificationEmailSent:
            case ProcessState.userInDynamicsWaitingForEmailVerification:
                return 'Check inbox';
            case ProcessState.userInDynamicsCreated: return 'Associate company';
            case ProcessState.mikeCloudRegistrationInvitationSent: return 'Nearly there!'
            default: return '';
        }
    }


    const ShowTitle = () => {
        //console.log(`ShowTile called:sendingEmail: ${sendingEmail}, processing: ${processing}`);
        return !sendingEmail && !processing
    };
    const ShowProgressBar = () => {
        //console.log(`ShowProgressBar called:sendingEmail: ${sendingEmail}, processing: ${processing}`);
        return !sendingEmail && !processing
    };

    const SendVerificationEmail = (newContact?: Contact, existingContactInfo?: ContactInfo, silent?: boolean) => {
        silent = silent === undefined ? false : silent;

        if (!silent) setSendingEmail(true);
        const customersAPI = context.customersApiService!;

        (async () => {
            try {
                if (newContact) {
                    await customersAPI!.EncryptContactAndSendEmail(newContact);
                    setSendingEmail(false);
                }
                else if (existingContactInfo) {
                    await customersAPI!.SendEmailVerificationCode(existingContactInfo.contactId);
                    setSendingEmail(false);
                }

                //setProcessState(ProcessState.verificationEmailSent);
            } catch (error: any) {
                setSendingEmail(false);
                setProcessState(ProcessState.error);
                setError(error);
            }
        })();
    }

    const UpdateContact = (contactId: string, contact: ContactUpdate): Promise<void> => {

        const customersAPI = context.customersApiService!;

        return (async () => {
            try {
                await customersAPI!.UpdateContact(contactId, contact);
            } catch (error: any) {
                setProcessState(ProcessState.error);
                setError(error);
            }
        })();
    }

    return <DialogContainer stepsCount={5} selectedStepNr={GetSelectedStepNr()} title={GetTitle()} showTitle={ShowTitle()} showProgressBar={ShowProgressBar()}>
        <div className={styles.ClientRegistration}>


            {/* ======================================================
             Initial page
            ========================================================*/}
            {processState === ProcessState.initialPage && <div>

                {/* We don't know you yet. Tell us about yourself */}
                <UserRegistration
                    email={email}
                    contactCode={contactCode}
                    afterRegistrationRedirectUrl={afterRegistrationRedirectUrl || ''}
                    afterRegistrationRedirectLinkText={afterRegistrationRedirectLinkText || ''}
                    onVerificationEmailSent={(newContact: Contact) => {
                        //  called when a new user has been successfully created
                        console.log("onVerificationEmailSent");
                        setNewContact(newContact);
                        setProcessing(false);
                        setProcessState(ProcessState.verificationEmailSent);
                        setContactInfo({ email: email, contactId: '', exists: false, registeredInMikeCloud: false, isAssociatedWithAccount: accountAssociated, gdprConsentProvided: true });
                    }}
                    onUserCreationRequested={(request: ContactRequest) => {
                        //  called when a new user has been successfully created
                        console.log("onUserCreationRequested");
                        setContactRequest(request);
                        setNewContact(request.contact!);
                        setProcessing(false);
                        setProcessState(ProcessState.waitingForEmailVerificationAndUserCreation);
                        setContactInfo({ email: email, contactId: '', exists: false, registeredInMikeCloud: false, isAssociatedWithAccount: accountAssociated, gdprConsentProvided: true });
                    }}
                    onCancelAction={onCancelAction}
                    cancelRedirectUrl={cancelRedirectUrl}
                    onUserExists={(contactInfo: ContactInfo) => {
                        //  called when email was found and used clicked at 'continue here'
                        setProcessing(false);
                        setContactInfo(contactInfo);
                        setEmail(contactInfo.email);
                        setAccountAssociated(contactInfo.isAssociatedWithAccount);

                        //update contact - store redirectUrl and gdpr consent
                        UpdateContact(contactInfo.contactId, {
                            RedirectUrlAfterRegistration: `${afterRegistrationRedirectUrl}|${afterRegistrationRedirectLinkText}`,
                            GdprConsentProvided: true
                        }).then(() => {
                            if (contactCode !== undefined) {
                                // invitation flow
                                setProcessState(ProcessState.userInDynamicsCreated);
                            } else {
                                SendVerificationEmail(undefined, contactInfo, true);
                                setProcessState(ProcessState.userInDynamicsWaitingForEmailVerification);
                            }
                        })

                    }}
                    onProcessingChanged={(running: boolean) => setProcessing(running)}
                />
            </div>}

            {/* ======================================================
             User encoded in Dynamics and waiting for email verification
            ========================================================*/}
            {(processState === ProcessState.verificationEmailSent ||
                processState === ProcessState.userInDynamicsWaitingForEmailVerification) && <>

                    <div className={`${styles.privacyPolicy} ${styles.font14} mt-3`}>
                        {(processState === ProcessState.verificationEmailSent) && <>Thank you for registering.</>} To verify your email address, follow the instructions and use the verification link sent to your email. The link will expire in 1 hour.
                    </div>
                    <div className={`${styles.privacyPolicy} ${styles.font14} mt-6 mb-8`}>
                        Didn’t receive the email? Please, check your spam folder or <LinkButton
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                SendVerificationEmail(newContact);
                            }
                            } >send the email again</LinkButton>.
                    </div>
                </>}

            {/* ======================================================
               User was encoded in Dynamics and therefore the email with verification code was sent automatically to the user. user then clicked the link and it opened
               page with this component in this state. It shows EmailVerification component and wait for the result
            ========================================================*/}
            {processState === ProcessState.waitingForEmailVerificationAndUserCreation && <div>

                <EmailVerification
                    contactRequest={contactRequest}
                    onEmailVerified={(contactInfo) => {
                        setEmail(contactInfo.email);
                        setProcessState(ProcessState.userInDynamicsCreated);
                        setContactInfo(contactInfo);
                        setAccountAssociated(contactInfo.isAssociatedWithAccount)
                    }}
                    onProcessingChanged={(running: boolean) => setProcessing(running)}
                />

            </div>}

            {/* ======================================================
               User exists or created in Dynamics his email was verified
            =========================================================*/}
            {(processState === ProcessState.userInDynamicsCreated ||
                processState === ProcessState.mikeCloudRegistrationInvitationSent) && <div>
                    <AccountAssociation
                        contactId={contactInfo!.contactId}
                        userEmail={email!}
                        accountAssociated={contactInfo!.isAssociatedWithAccount}
                        onProcessingChanged={(running: boolean) => setProcessing(running)}
                        onMikeCloudRegistrationSucceeded={() => { setProcessState(ProcessState.mikeCloudRegistrationInvitationSent); }}
                        onExistingMikeCloudUserPaired={(redirUrl, redirText) => {
                            console.log("onExistingMikeCloudUserPaired - redirecting to finished page...");
                            history.push('/finished', { redir: redirUrl, redirtxt: redirText });
                        }}
                    />
                </div>}

            {/* ======================================================
              When sending email
            =========================================================*/}
            {
                sendingEmail && <Progress text="Email is being sent to you..." />
            }

            {/* ======================================================
              Error
            =========================================================*/}
            {
                processState === ProcessState.error && <div>
                    <h1>Error</h1>
                    <div>{error?.message || 'An error occurred'}</div>

                </div>
            }

        </div >
    </DialogContainer >
};

export default ClientRegistration;
