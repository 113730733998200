import { IAppContext } from 'AppContext';
import { useEffect, useState } from 'react';
import { ISettingsApiService } from '../../services/api/CustomersApiService'
import { ServiceResponse } from '../api-common/ApiResponse';
export interface IAppSettings {
    load(): Promise<void>;
    loaded: boolean;

    //settings properties
    hotlineEmail: string;
    bgExpirationIntervalMinutes: number;
    bcgImages: string[];
    MikeCloudLogoUrl: string;
    AcademyLogoUrl: string;
    DhiLogoUrl: string;
}

export class AppSettings implements IAppSettings {
    settingsService: ISettingsApiService;


    /**
     *
     */
    constructor(settingsService: ISettingsApiService) {
        this.settingsService = settingsService;
    }
    load = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            this.settingsService.GetSettingSection<IAppSettings>("Customers:UI").then(
                (settings: ServiceResponse<IAppSettings>) => {
                    Object.assign(this, settings.response);
                    console.log(`Assigned settings: ${this.hotlineEmail}, ${this.bcgImages}`);
                    this.loaded = true;
                    resolve();
                }
            );
        });
    }

    loaded: boolean = false;
    hotlineEmail: string = "";
    bcgImages = [];
    bgExpirationIntervalMinutes = 60;
    MikeCloudLogoUrl = "";
    AcademyLogoUrl = "";
    DhiLogoUrl = "";
}

export const useAppSettings = (context: IAppContext): { appSettings: IAppSettings | undefined, loaded: boolean } => {

    const [hasBeenLoaded, setHasBeenLoaded] = useState<boolean>(false);
    const appSettings = context.appSettings;

    useEffect(() => {
        if (!appSettings)
            return;

        if (appSettings.loaded) {
            setHasBeenLoaded(true);
        } else {
            appSettings.load().then(() => {
                setHasBeenLoaded(true);
            })
        }
    }, [appSettings]);
    return { appSettings: appSettings, loaded: hasBeenLoaded };
}