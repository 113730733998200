
class Patterns {
    alphaNumeric;
    emailLocalPart;
    emailDomainPart;

    constructor() {
        this.alphaNumeric = /^[A-Za-zÀ-ÖØ-öø-įĴ-őŔ-žǍ-ǰǴ-ǵǸ-țȞ-ȟȤ-ȳɃɆ-ɏḀ-ẞƀ-ƓƗ-ƚƝ-ơƤ-ƥƫ-ưƲ-ƶẠ-ỿ\d\s-,./]*$/i;
        this.emailLocalPart = /^(?!.*\.\.)(?!^\.)[\w.-]+(?<!\.)$/i;
        this.emailDomainPart = /^([-\w]+(\.[-\w]+)+)[\w-]+$/i;
    }

    create() {
        return new Patterns();
    }
}

const patterns = new Patterns();

class InputValidation {
    static readonly patterns = new Patterns();

    static isEmailValid(value: string) {
        const atSeparator = value.split('@');
        if (value.length < 500 && atSeparator.length == 2) {
            const [local, domain] = value.split('@');
            const isLocalValid = local.length > 0 && InputValidation.patterns.emailLocalPart.test(local);
            const isDomainValid = domain.length > 0 && InputValidation.patterns.emailDomainPart.test(domain);
            return isLocalValid && isDomainValid;
        }
        return false;
    }

    static isCompanyNameValid(value: string) {
        const companyNamePattern = /^[A-Za-zÀ-ÖØ-öø-įĴ-őŔ-žǍ-ǰǴ-ǵǸ-țȞ-ȟȤ-ȳɃɆ-ɏḀ-ẞƀ-ƓƗ-ƚƝ-ơƤ-ƥƫ-ưƲ-ƶẠ-ỿ\d\s-,./+&']+$/
        return companyNamePattern.test(value);
    }
    
}

export { patterns, InputValidation }